import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Landpage from '../../assets/svg/landing-page/landpage.jpg';

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <div className={`landing-page-container-v2 ${isLoaded ? 'show-gradient' : ''}`}>
      <LazyLoadImage
        className="footer-img"
        src={Landpage}
        alt="Content"
        effect="blur"
        afterLoad={handleImageLoaded}
      />
    </div>
  );
};

export default LandingPage;
