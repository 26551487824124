import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message } from 'antd';
import React from 'react';
import { showConfirmationModal } from '../utils/helpers/purefunctions';

const TrackOrderButton = ({ label, order }) => {
  const deliveryDetails = order?.meta ? JSON.parse(order?.meta) : {};

  const url = deliveryDetails.trackingUrl;
  const isNAUrl = url === 'N/A';
  const appId = '2170020135085685';
  const initialStatuses = ['ASSIGNED', 'ACCEPTED', 'ASSIGNING_RIDER', 'CANCELLED'];
  const disableTrackOrder = order.isBroadcastOndemand && initialStatuses.includes(order.status);

  const handleClick = async () => {
    if (disableTrackOrder) {
      return;
    }
    if (isNAUrl || !url) {
      message.error('Order has no tracking URL', 3.0);
      return;
    }
    showConfirmationModal(url);
  };

  return (
    <div
      className={`track-order ${label} ${disableTrackOrder ? 'disable' : ''}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={solid('location-arrow')} />
      <span>Track Order</span>
    </div>
  );
};
export default TrackOrderButton;
