import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import ParcelSVG from '../../../../shared/assets/svg/Parcels-icon-pink.svg';
import Banner from '../../../../shared/assets/images/Banners/Banner-3x.png';
import HELP from '../../../../shared/assets/svg/home-booking/header_help.svg';
import MENU from '../../../../shared/assets/svg/home-booking/header_menu.svg';
import SEARCH from '../../../../shared/assets/svg/home-booking/header_search.svg';
import { useNavigate } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { setBannersList } from '../../../../shared/redux/banner/actions';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { WELCOME_TO_PARCELS } from '../../../../shared/utils/enums/AppConstants';
// import { setAppInfo } from '../../../../shared/redux/app/actions';

import {
  isEmpty,
} from '../../../../shared/utils/helpers/purefunctions';
// import AppInfoDAO from '../../../../shared/utils/dao/App'

const Header = ({ bannersList, setBannersList, hasFilled }) => {
  const storage = getStorage();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const { appInfo } = useSelector(state => state.appAccessReducer);

  const goToMyAccounts = () => navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);
  const goToHelp = () => navigate(MODULE_PATH.EXTRAS.HELP_CENTER);
  const goToSearchOrders = () => navigate(MODULE_PATH.ORDERS.SEARCH_ORDER);

  const [banners, setBanners] = useState([]);

  const fetchBanners = async () => {
    const isPreProduction = false;
    const folderPath = `GCASH/${isPreProduction ? 'PRE_PROD_BANNER' : 'BANNERS'}`;
    try {
      const urls = [];
      let fileNumber = 1;
      let maxFiles = 10;

      while (fileNumber <= maxFiles) {
        const fileName = `carousel-img${fileNumber}-pink.png`;
        const fileRef = ref(storage, `${folderPath}/${fileName}`);
        try {
          const downloadUrl = await getDownloadURL(fileRef);
          urls.push(downloadUrl);
          fileNumber++;
        } catch (error) {
          if (error.code === 'storage/object-not-found') {
            break;
          } else {
            break;
          }
        }
      }

      setBanners(urls);
      setBannersList(urls);
    } catch (error) {
      setBanners([Banner]);
      setBannersList([Banner]);
    }
  };

  // useEffect(() => {
  //   const appInfoDAO = new AppInfoDAO();

  //   async function fetchAndUseAppInfo() {
  //     const appInfo = await appInfoDAO.fetchAppInfo();
  //     if (appInfo) {
  //       dispatch(setAppInfo(appInfo))
  //     }
  //   }

  //   if (!appInfo) {
  //     fetchAndUseAppInfo();
  //   }
  // }, []);

  useEffect(() => {
    if (!bannersList.length) {
      fetchBanners();
    } else {
      setBanners(bannersList);
    }
  }, [bannersList]);

  const sortImagesByOrder = (imageArray, orderArray) => {
    var indexMap = {};
    imageArray.forEach((image, index) => {
      indexMap[image] = index;
    });

    imageArray.sort((a, b) => {
      var indexA = indexMap[a];
      var indexB = indexMap[b];
      var orderIndexA = orderArray.indexOf(String(indexA + 1));
      var orderIndexB = orderArray.indexOf(String(indexB + 1));

      if (orderIndexA === -1 && orderIndexB === -1) {
        return 0;
      }

      if (orderIndexA !== -1 && orderIndexB === -1) {
        return -1;
      }

      if (orderIndexA === -1 && orderIndexB !== -1) {
        return 1;
      }
      return orderIndexA - orderIndexB;
    });
  
    return imageArray;
  }

  const newBanner = banners.length ? sortImagesByOrder(banners.filter(url => !url.includes("Banner-3x")), appInfo?.bannerOrder || []) : [Banner];

  return (
    <>
      <div className="header-container">
        <div className="parcel-logo">
          <img src={`${ParcelSVG}?v=${new Date().getTime()}`} alt="Parcel PH Logo" />
        </div>
        <div className="greetings-container">
          <h4>{WELCOME_TO_PARCELS}</h4>
        </div>
        <div className="header-icon-container">
          <div className="search-icon-container" onClick={goToSearchOrders}>
            <img src={SEARCH} alt="search" />
          </div>
          <div className="search-icon-container" onClick={goToHelp}>
            {/* <img src={HELP} alt="help" /> */}
            <span>HELP</span>
          </div>
          <div className="search-icon-container" onClick={goToMyAccounts}>
            <img src={MENU} alt="menu" />
          </div>
        </div>
      </div>
      {!hasFilled && (
        <Carousel dots={false} autoplay>
          {newBanner.map((img, idx) => (
            <div key={`container--${idx}`}>
              <img key={`banner--${idx}`} src={img} alt="Parcel Banner" className="parcel-banner" />
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  propsUser: state.usersReducer.currentUser,
  bannersList: state.bannerReducer.bannersList,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setBannersList,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
