import { Button } from 'antd';
import React from 'react';

const PaymentSummaryBtn = ({ name, onClick, proceedPaymentSummary, className }) => {
  return (
    <Button
      className={`payment-summary-btn ${proceedPaymentSummary ? 'proceed' : ''} ${className}`}
      onClick={() => onClick()}
    >
      {name}
    </Button>
  );
};

export default PaymentSummaryBtn;
