import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MODULE_PATH } from '../constants/Module';
import FindCourier from '../assets/images/OndemandDelivery/find_courier.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeName,
  formatBills,
  isEmpty,
  filterCourierPartner,
} from '../utils/helpers/purefunctions';
import { message, Skeleton, Space } from 'antd';
import { COURIERS } from '../utils/enums/DeliveryConstants';
import { setCourier, setCurrentCourierRates } from '../redux/onDemand/actions';
import DPHDAO from '../utils/dao/DPH';
import LoaderSpinner from './LoaderSpinner';
import { COURIER_IMAGES } from '../utils/enums/CourierImages';
import { ICON } from '../../shared/utils/enums/AppIcons';
import { BOOK_ANY } from '../utils/enums/AppConstants';
import { FOOD_PANDA, PANDAGO } from '../utils/enums/Providers';
import { onDemandPayload } from '../utils/helpers/Payload';

const Courier = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onDemandCouriers, setOnDemandCouriers] = useState([]);
  const { pickUp, dropOff } = useSelector(state => state.onDemandReducer);
  const courierMode = useSelector(state => state.usersReducer.currentUser.courierMode);
  const onDemandProps = useSelector(state => state.onDemandReducer);
  const courier = useSelector(state => state.onDemandReducer.courier);
  const prevCourierMode = !(courierMode === 'regular');

  const onDemandPartners = onDemandProps.onDemandPartners;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [onDemand] = await Promise.all([
          onDemandPartners.length > 0
            ? DPHDAO.getOnDemandQuotation(
                onDemandPayload(filterCourierPartner(onDemandPartners, COURIERS))
              )
            : Promise.resolve([]),
        ]);
        setOnDemandCouriers(onDemand);

        if (onDemand.length !== 0) {
          const highestRatedCourier = onDemand.reduce(
            (maxRate, obj) => (obj.rate > maxRate.rate ? obj : maxRate),
            onDemand[0]
          );
          dispatch(
            setCourier({
              courier: BOOK_ANY,
              rate: highestRatedCourier.rate,
              vehicleType: onDemandProps.vehicleType.value,
            })
          );

          const currentCourierRates = onDemand.reduce((rates, { courier, rate }) => {
            rates[courier] = rate;
            return rates;
          }, {});
          dispatch(setCurrentCourierRates(currentCourierRates));
        }
      } catch (error) {
        // console.log('Error fetching data:', error);
      }
    };

    if (prevCourierMode && hasAddress && onDemandCouriers.length === 0 && isEmpty(courier)) {
      fetchData();
    }
  }, [onDemandPartners]);

  const handleSelectCourier = () => {
    // COMMENT FOR TESTING COURIER PURPOSES
    if (isEmpty(pickUp?.address) || isEmpty(dropOff?.address)) {
      return message.error('Pickup and Drop-off address are required.', 3.0);
    }

    navigate(MODULE_PATH.SHARED.COURIER, {});
  };

  const hasAddress = !isEmpty(pickUp?.address) && !isEmpty(dropOff?.address);
  const courierInfoLabel = () => {
    if (!isEmpty(courier) && hasAddress) {
      return capitalizeName(
        courier.courier === FOOD_PANDA ? PANDAGO : courier.courier.toLowerCase()
      );
    }
    if (prevCourierMode && hasAddress) {
      return BOOK_ANY;
    }
    return 'Find a courier';
  };

  const courierInfoRate = () => {
    if (!isEmpty(courier) && hasAddress) {
      return `P ${formatBills(courier.rate)}`;
    }
    if (prevCourierMode && hasAddress && isEmpty(courier)) {
      return <LoaderSpinner />;
    }
    return;
  };

  const courierDetails = () => {
    if ((!isEmpty(courier) && hasAddress) || (prevCourierMode && hasAddress)) {
      return null;
    }
    return 'Courier Description';
  };
  const courierImage =
    courier.courier === BOOK_ANY ? ICON.BOOK_ANY : COURIER_IMAGES[courier?.courier?.toUpperCase()];
  return (
    <div className="courier" onClick={() => handleSelectCourier()}>
      <div className="title">Couriers</div>
      <div className="info">
        <img src={isEmpty(courier) ? FindCourier : courierImage} alt="Find courier" />
        <div className="courier-info">
          <div className="header">
            {courierInfoLabel()} {isEmpty(courier) && !prevCourierMode && <span>*</span>}
          </div>
          <div className="details">{courierDetails()}</div>
        </div>
        <div className="price">{courierInfoRate()}</div>
        <FontAwesomeIcon icon={solid('angle-right')} />
      </div>
    </div>
  );
};

export default Courier;
