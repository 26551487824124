export const ADDRESSBOOK_INITIAL_STATE = {
  address: {},
  recentlyAddress: {},
  defaultAddress: {},
  // defaultAddress: {
  //   latitude: 0,
  //   longitude: 0,
  //   senderDetails: "",
  //   notesToRider: "",
  //   emailAddress: "",
  //   address: "",
  //   pickupAddress: "",
  //   barangay: "",
  //   pickupCity: "",
  //   province: "",
  // },
  selectedAddress: {},
  listOfAddress: [],
};

export const REF = {
  CONTACT_NO: 'contactNo',
  FULLNAME: 'fullName',
  EMAIL: 'emailAddress',
  UNIT_FLOOR: 'unitFloorHouse',
  BLG_STREET: 'bldgStreet',
  PROV_CITY_BRGY: 'provinceCityBarangay',
  PROVINCE: 'province',
  CITY: 'city',
  BARANGAY: 'barangay',
  POSTAL_CODE: 'postalCode',
  LANDMARK: 'landmark',
  ADDRESS_TYPE: 'addressType',
  NOTES_TO_RIDER: 'notesToRider',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
};

export const INITIAL_STATE = {
  [REF.CONTACT_NO]: null,
  [REF.FULLNAME]: '',
  [REF.EMAIL]: '',
  [REF.UNIT_FLOOR]: '',
  [REF.BLG_STREET]: '',
  [REF.PROV_CITY_BRGY]: '',
  [REF.PROVINCE]: '',
  [REF.CITY]: '',
  [REF.BARANGAY]: '',
  [REF.POSTAL_CODE]: '',
  [REF.LANDMARK]: '',
  [REF.ADDRESS_TYPE]: '',
  [REF.NOTES_TO_RIDER]: '',
  [REF.LATITUDE]: '',
  [REF.LONGITUDE]: '',
};
