import React, { useState, useContext, useEffect, useCallback } from 'react';
import Header from './components/Header';
import LegendOrders from './components/LegendOrders';
import Orders from './orders';
// import PendingOrders from './pendingOrders';

import { bindActionCreators } from 'redux';
import { Modal } from 'antd';
import {
  setFormView,
  fetchPendingOrders,
  fetchForRefundOrders,
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchOrdersFromMongo,
  fetchCashbackTransactions,
} from '../../../shared/redux/home/actions';
import { fetchListOfAddress, setSelectedAddress } from '../../../shared/redux/addressBook/actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setPickupDateTime, setOnDemandInitialState } from '../../../shared/redux/onDemand/actions';
import { setPickupDateTime as setStandardPickupDateTime } from '../../../shared/redux/standard/actions';
import { setFetchALL } from '../../../shared/redux/home/actions';
import {
  setTimeInterval,
  getServerTime,
  isEmpty,
} from '../../../shared/utils/helpers/purefunctions';
import ItemTypeContext from '../../../shared/context/ItemTypeContext';
import ItemDecriptionContext from '../../../shared/context/ItemDescriptionContext';
import ItemRateContext from '../../../shared/context/ItemRateContext';

import Discover from '../../../shared/assets/svg/discover.jpg';

import * as Notification from '../../../shared/elements/Notification';
import {
  setAppTourIdx,
  setAppDisclaimer,
  setAppDiscover,
  setShowedToast,
  setAppLegend,
} from '../../../shared/redux/app/actions';

import { LazyLoadImage } from 'react-lazy-load-image-component';

function Home({
  fetchPendingOrders,
  fetchForRefundOrders,
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchOrdersFromMongo,
  user,
  fetchListOfAddress,
  setSelectedAddress,
  setFetchALL,
  appAccess,
  fetched,
  fetchCashbackTransactions,
}) {
  const {
    orderList,
    orderListMongo,
    forRefundOrders,
    cancelledOrders,
    completedOrderList,
    formTab,
  } = useSelector(state => state.homeReducer);
  const onDemand = useSelector(state => state.onDemandReducer);
  const standard = useSelector(state => state.standardReducer);
  const dispatch = useDispatch();

  const orders = useSelector(state => state.homeReducer.orderList);

  const { setType } = useContext(ItemTypeContext);
  const { setDescription } = useContext(ItemDecriptionContext);
  const { setItemPrice } = useContext(ItemRateContext);
  const [setServerTime, onSetServerTime] = useState(false);
  // const [discover, onShowDiscover] = useState(true);
  const [discoverClass, onSetDiscoverClass] = useState('initialize');
  const [toastCompleted, onSetToastCompleted] = useState(false);
  const [toastCancelled, onSetToastCancelled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const isOndemand = 'onDemand-view' === formTab;
  const deliveryProps = isOndemand ? onDemand : standard;
  const { pickUp, dropOff, courier: selectedCourier, itemType } = deliveryProps;
  const courier = selectedCourier ? selectedCourier.courier : null;

  const hasFilled = [
    pickUp?.senderDetails?.address,
    dropOff?.recipientDetails?.address,
    itemType,
    courier,
  ].some(value => !isEmpty(value));

  useEffect(() => {
    if (hasFilled) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [hasFilled, onDemand, standard]);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setType('');
    setDescription('');
    setItemPrice('');
  }, [setType, setDescription, setItemPrice]);

  const hasOrder = [
    cancelledOrders,
    orderList,
    orderListMongo,
    completedOrderList,
    forRefundOrders,
    setFetchALL,
  ].some(array => !isEmpty(array));

  useEffect(() => {
    if (appAccess.disclaimer) {
      document.body.classList.add('no-scroll');
    }
    if (user.touredForm) {
      document.body.classList.remove('no-scroll');
    }
    if (fetched && user.recordCreated && !appAccess.showedToast) {
      let endStatusCancelledCount;
      let endStatusCompletedCount;
      const endStatusCancelled = [...orderListMongo, ...cancelledOrders];
      endStatusCancelledCount = endStatusCancelled.filter(
        item => item.createdAt >= user.lastLoggedIn
      );
      onSetToastCancelled(endStatusCancelledCount.length);

      const endStatusCompleted = [...completedOrderList];
      endStatusCompletedCount = endStatusCompleted.filter(
        item => item.createdAt >= user.lastLoggedIn
      );
      onSetToastCompleted(endStatusCompleted.length);

      if (typeof appAccess.legend === 'undefined') {
        toastDisplay();
      }
    }
  }, [user, appAccess, fetched]);

  const toastDisplay = () => {
    if (toastCompleted) {
      const message =
        toastCompleted === 1
          ? `1 Deliveries has been completed.`
          : `${toastCompleted} Deliveries have been completed`;
      setTimeout(async () => {
        Notification.toast('success', 'end-status-completed', 'Delivery Complete', `${message}`);
      }, 1000);
    }

    if (toastCancelled) {
      const message =
        toastCancelled === 1
          ? `1 Deliveries has been cancelled.`
          : `${toastCancelled} Deliveries have been cancelled`;
      setTimeout(async () => {
        Notification.toast('error', 'end-status-cancelled', 'Delivery Cancel', `${message}`);
      }, 1500);
    }

    dispatch(setShowedToast(true));
  };

  const handleOkDisclaimer = () => {
    dispatch(setAppDisclaimer(false));
    document.body.classList.remove('no-scroll');
    setTimeout(async () => {
      // onShowDiscover(true);
      onSetDiscoverClass('display');
      document.body.classList.add('no-scroll');
      dispatch(setAppDiscover(true));
    }, 250);
  };

  const handleCloseDiscover = () => {
    // onShowDiscover(false);
    onSetDiscoverClass('hide');

    setTimeout(async () => {
      dispatch(setAppTourIdx(true));
    }, 250);
  };

  useEffect(() => {
    if (hasOrder) {
      setFetchALL(true);
    }
  }, [hasOrder, setFetchALL]);

  /* Don't include fetchPendingOrders and fetchDoneOrders as dependency to avoid flickering in orderDetails*/
  useEffect(() => {
    fetchPendingOrders(user.id);
    fetchForRefundOrders(user.id);
    fetchCancelledOrders(user.id);
    fetchOrdersFromMongo(user.id);
    fetchCashbackTransactions(user.id);
    fetchDoneOrders(user.id, 'false');
  }, [fetchOrdersFromMongo, fetchCashbackTransactions, user.id]);

  useEffect(() => {
    fetchListOfAddress(user.id);
  }, []);

  useEffect(() => {
    setFormView();
    setSelectedAddress({});
  }, []);

  useEffect(() => {
    !setServerTime && setCurrentDateAndTime();
  }, [setServerTime]);

  const setCurrentDateAndTime = async () => {
    const currDateTime = await getServerTime();
    const dateTime = setTimeInterval(currDateTime);

    const intervalPres = 300000;

    const hasOnDemandPickupDateTime =
      onDemand.pickupDateTime && onDemand.pickupDateTime % intervalPres === 0;

    const hadStandardPickupDateTime =
      standard.pickupDateTime && standard.pickupDateTime % intervalPres === 0;
    // removed: pickupDateTime default value is null
    if (hasOnDemandPickupDateTime) {
      dispatch(setPickupDateTime(onDemand.pickupDateTime));
    } else {
      dispatch(setPickupDateTime(dateTime));
    }

    if (hadStandardPickupDateTime) {
      dispatch(setStandardPickupDateTime(standard.pickupDateTime));
    } else {
      dispatch(setStandardPickupDateTime(dateTime));
    }

    onSetServerTime(true);
  };

  const handleEligibility = () => {
    if (typeof appAccess.tourIdx === 'boolean') {
      dispatch(setAppTourIdx(!appAccess.tourIdx));
    }
    if (appAccess?.legend) {
      dispatch(setAppLegend(appAccess?.legend === true ? 1 : undefined));
    }
  };

  return (
    <>
      <Modal
        title="Disclaimer"
        open={appAccess.disclaimer}
        onOk={() => handleOkDisclaimer()}
        okText="Agree"
        className="disclaimer-modal"
      >
        <span>
          The Products/Services are being solely provided by the Partner Merchant. GCash makes no
          representation or warranties of any kind, express or implied, about the quality, quantity,
          completeness, fitness for a particular purpose, and/or result(s) of such Products/Services
          are therefore strictly at your own risk and you release G-Xchange, Inc. and its affiliates
          from any liability whatsoever resulting from such availment. You acknowledge that it is
          your sole responsibility to understand and accept the conditions of the applicable terms
          and conditions of the Products/Services before availing of the same. If you have any
          questions or concerns about the Products/Services, we encourage you to direct them with
          the Partner Merchant.
        </span>
      </Modal>
      {user?.tour?.touredForm === false && (
        <Modal
          open={discoverClass !== 'hide'}
          onOk={() => handleCloseDiscover()}
          okText="Discover"
          className={`discover-modal ${discoverClass}`}
        >
          <LazyLoadImage
            className="footer-img"
            src={Discover}
            alt="Discover"
            effect="blur"
            onLoad={handleImageLoad}
          />
          <span>Welcome to Parcels! 💖</span>
        </Modal>
      )}
      <div
        className={`home-container ${user.touredForm ? '' : 'disabled-scroll'}`}
        onClick={() => handleEligibility()}
      >
        <Header user={user.firstName} hasFilled={false} />
        {user.recordCreated && <LegendOrders />}
        <span className="new-order-title">New Order</span>
        <Orders />
      </div>
    </>
  );
}
const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
      setOnDemandInitialState,
      fetchPendingOrders,
      fetchForRefundOrders,
      fetchCancelledOrders,
      fetchDoneOrders,
      fetchOrdersFromMongo,
      fetchListOfAddress,
      setSelectedAddress,
      setFetchALL,
      fetchCashbackTransactions,
    },
    dispatch
  );
};
const mapStateToProps = state => ({
  user: state.usersReducer.currentUser,
  fetched: state.homeReducer.fetch,
  appAccess: state.appAccessReducer,
});
export default connect(mapStateToProps, matchDispatchToProps)(Home);
