import {
  SET_STANDARD_ORDER_ID,
  SET_STANDARD_COURIER,
  SET_STANDARD_PICK_UP,
  SET_STANDARD_DROP_OFF,
  SET_PRODUCT,
  SET_STANDARD_ITEM_TYPE,
  SET_STANDARD_ITEM_DESCRIPTION,
  SET_STANDARD_INITIAL_STATE,
  SET_STANDARD_POINT_LOCATION,
  SET_STANDARD_PICKUP_DATETIME,
  SET_STANDARD_ITEM_PRICE,
  SET_STANDARD_ITEM_APPROX_WEIGHT,
  SET_STANDARD_PICKUP_TIME,
  SET_STANDARD_PAYMENT_REQUEST_ID,
  SET_LOCATION,
  SET_STANDARD_CASHBACK_AMOUNT,
} from '../enums/types';

export const setStandardInitialState = payload => ({
  type: SET_STANDARD_INITIAL_STATE,
  payload,
});

export const setOrderId = orderId => ({
  type: SET_STANDARD_ORDER_ID,
  payload: orderId,
});

export const setPointLocation = pointLocation => ({
  type: SET_STANDARD_POINT_LOCATION,
  payload: pointLocation,
});

export const setLocation = location => ({
  type: SET_LOCATION,
  payload: location,
});

export const setCourier = courier => ({
  type: SET_STANDARD_COURIER,
  payload: courier,
});

export const setPickUp = pickUp => ({
  type: SET_STANDARD_PICK_UP,
  payload: pickUp,
});

export const setDropOff = dropOff => ({
  type: SET_STANDARD_DROP_OFF,
  payload: dropOff,
});

export const setPickupDateTime = pickupDateTime => ({
  type: SET_STANDARD_PICKUP_DATETIME,
  payload: pickupDateTime,
});

export const setProduct = product => ({
  type: SET_PRODUCT,
  payload: product,
});

export const setStandardItemType = itemType => ({
  type: SET_STANDARD_ITEM_TYPE,
  payload: itemType,
});

export const setStandardItemDescription = itemDescription => ({
  type: SET_STANDARD_ITEM_DESCRIPTION,
  payload: itemDescription,
});

export const setItemPrice = itemPrice => ({
  type: SET_STANDARD_ITEM_PRICE,
  payload: itemPrice,
});

export const setItemApproxWeight = itemApproxWeight => ({
  type: SET_STANDARD_ITEM_APPROX_WEIGHT,
  payload: itemApproxWeight,
});

export const setPickUpTime = pickUpTime => ({
  type: SET_STANDARD_PICKUP_TIME,
  payload: pickUpTime,
});

export const setStandardPaymentRequestId = paymentRequestId => ({
  type: SET_STANDARD_PAYMENT_REQUEST_ID,
  payload: paymentRequestId,
});

export const setStandardCashBackAmount = cashBack => ({
  type: SET_STANDARD_CASHBACK_AMOUNT,
  payload: cashBack,
});
