import React, {useRef, useEffect } from 'react';
const ScrollToBottom = ({hasFilled}) => {
  const elementRef = useRef();

  useEffect(() => {
    hasFilled && elementRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  }, [hasFilled]);

    return <div style={{ height: '1px', width: '100%' }} ref={elementRef} />;
};
export default ScrollToBottom;
