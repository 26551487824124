import DEFAULT from '../../assets/images/Couriers Images/On-demand Couriers/Courier-no-icon.png';
import BORZO from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import MRSPEEDY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import GRAB from '../../assets/images/Couriers Images/On-demand Couriers/Grab.svg';
import LALAMOVE from '../../assets/images/Couriers Images/On-demand Couriers/Lalamove.svg';
import FOOD_PANDA from '../../assets/images/Couriers Images/On-demand Couriers/Pandago.svg';
import TRANSPORTIFY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Transportify.png';
import NINJAVAN from '../../assets/images/Couriers Images/On-demand Couriers/NinjaVan.svg';
import QUADX from '../../assets/images/Couriers Images/On-demand Couriers/QuadX.svg';
import XDE from '../../assets/images/Couriers Images/Standard Couriers/2x/XDE.png';
import CLIQNSHIP from '../../assets/images/Couriers Images/Standard Couriers/2x/Cliqnship.png';

export const COURIER_IMAGES = {
  MRSPEEDY,
  BORZO,
  GRAB,
  LALAMOVE,
  TRANSPORTIFY,
  NINJAVAN,
  QUADX,
  XDE,
  CLIQNSHIP,
  DEFAULT,
  FOOD_PANDA,
};
