import produce from 'immer';
import {
  SET_APP_ACCESS,
  SET_APP_DISCLAIMER,
  SET_APP_DISCOVER,
  SET_APP_TOUR_IDX,
  SET_APP_SHOW_TOAST,
  SET_APP_LEGEND,
  SET_APP_VIEW_ALL,
  SET_APP_INFO,
} from '../enums/types';
import { APP_ACCESS } from '../../utils/enums/AppConstants';

const initialState = {
  appAccess: APP_ACCESS.WELCOME,
  disclaimer: false,
  discover: null,
  legend: false,
  viewAll: false,
  tourIdx: null,
  showedToast: false,
  appInfo: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_APP_ACCESS:
        draft.appAccess = action.payload;
        break;
      case SET_APP_DISCLAIMER:
        draft.disclaimer = action.payload;
        break;
      case SET_APP_DISCOVER:
        draft.discover = action.payload;
        break;
      case SET_APP_LEGEND:
        draft.legend = action.payload;
        break;
      case SET_APP_VIEW_ALL:
        draft.viewAll = action.payload;
        break;
      case SET_APP_TOUR_IDX:
        draft.tourIdx = action.payload;
        break;
      case SET_APP_SHOW_TOAST:
        draft.showedToast = action.payload;
        break;
      case SET_APP_INFO:
        draft.appInfo = action.payload;
        break;
      default:
        return draft;
    }
  });
