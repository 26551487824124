import React, { useState } from 'react';
import { Drawer, Space, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from './SubmitButton';
import FormInput from './FormInput';
import { formatBills, formatText } from '../utils/helpers/purefunctions';
import { DRIVER_TIP_LIST } from '../utils/enums/AppConstants';
import { useDispatch } from 'react-redux';
import { setDriversTip } from '../redux/onDemand/actions';

const DriverTipList = ({ show, onSetDriversTipDrawer, tip, setTip }) => {
  const [inputFocus, setInputFocus] = useState(null);
  const [fixTip, setFixTip] = useState(null);
  const [customTip, setCustomTip] = useState(null);
  const dispatch = useDispatch();

  const TipAmountDisplay = () => {
    return (
      <>
        {DRIVER_TIP_LIST.map((data, index) => (
          <span
            style={{ padding: '8px', fontSize: '13px' }}
            className={`tip ${
              parseFloat(customTip).toFixed(2) === parseFloat(data).toFixed(2) ? 'selected' : ''
            }`}
            onClick={() => {
              setCustomTip('');
              if (fixTip !== data) {
                setFixTip(data);
                setCustomTip(data);
              } else {
                setFixTip('');
                setCustomTip('');
              }
            }}
            key={index}
          >
            <span>
              <span className="peso">P</span>
              {parseFloat(data).toFixed(2)}
            </span>
          </span>
        ))}
      </>
    );
  };
  const handleTip = () => {
    if (!tip && !customTip && !fixTip) {
      return;
    }
    if (!fixTip && customTip && customTip < 20) {
      message.error('Tip amount should not be less than P 20.00');
      return;
    }

    onSetDriversTipDrawer();
    setTip(Number(customTip || fixTip));
    dispatch(setDriversTip(Number(customTip || fixTip || 0)));
  };
  const handleOnChange = value => {
    setFixTip(null);

    if (value > 2000) {
      setCustomTip(2000);
    } else {
      setCustomTip(value);
    }
  };
  const handleCloseBtn = () => {
    onSetDriversTipDrawer();
    if (!tip) {
      setCustomTip('');
      setFixTip('');
    }
  };
  return (
    <Drawer
      title="Add Driver's Tip"
      placement="bottom"
      height="280px"
      className="driver-tip-drawer"
      onClose={() => onSetDriversTipDrawer()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => handleCloseBtn()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="tipping-drawer-container">
        <span className="title">Choose tip amount</span>
        <div className="tip-list">
          <div className="viewport">
            <div className="horizontal">
              <TipAmountDisplay />
            </div>
          </div>
        </div>
        <FormInput
          value={customTip}
          onChange={value => {
            handleOnChange(value);
          }}
          onClick={() => setCustomTip('')}
          onFocus={() => setInputFocus('tip')}
          inputFocusName={inputFocus === 'tip'}
          onBlur={() => setInputFocus(null)}
          placeholder="Custom Amount (P 20.00 to P 2,000.00)"
          hasCounter={false}
          prefixOn="AMOUNT"
          type="NUMBER"
          pattern="[0-9]*"
          inputMode="numeric"
        />
        <SubmitButton
          isProceedValue={fixTip || customTip || tip}
          name={tip ? 'Edit Tip' : 'Add Tip'}
          onClick={() => handleTip()}
        />
      </div>
    </Drawer>
  );
};
export default DriverTipList;
