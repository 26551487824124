import React, { useState } from 'react';
import moment from 'moment-timezone';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import startMapMarker from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import endMapMarker from '../../../../shared/assets/images/location/map-marker-alt.png';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPointLocation } from '../../../../shared/redux/onDemand/actions';
import { setLocation } from '../../../../shared/redux/standard/actions';
import { SCHEDULE } from '../../../../shared/utils/enums/AppIcons';
import { PICK_CARD } from '../../../../shared/constants/OnDemand';
import { DAY_MMM_D_HH_MM, KEEN_DATE } from '../../../../shared/utils/enums/dateFormats';
import Schedule from './Schedule';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';

const PickupDropOff = ({ setPointLocation }) => {
  const navigate = useNavigate();
  const ondemand = useSelector(state => state.onDemandReducer);
  const scheduledDateTime = useSelector(state => state.onDemandReducer.pickupDateTime);
  const pickUpTime = useSelector(state => state.onDemandReducer.pickUpTime);
  const [showScheduleDrawer, setScheduleDrawer] = useState(false);
  const dispatch = useDispatch();
  const { pickUp, dropOff } = ondemand;

  const onClickPickUpDropOff = pointLocation => {
    setPointLocation(pointLocation);

    const addressInfo =
      pointLocation === 'PickUp' ? pickUp?.senderDetails : dropOff?.recipientDetails;

    if (pointLocation === 'PickUp') {
      if (!isEmpty(pickUp.senderDetails)) {
        dispatch(setLocation(addressInfo?.address || pickUp?.address));
        return navigate(MODULE_PATH.ADDRESS.PICKUP_DETAILS, { state: { showAutoComplete: true } });
      }
    }
    if (pointLocation === 'DropOff') {
      if (!isEmpty(dropOff.recipientDetails)) {
        dispatch(setLocation(addressInfo?.address || dropOff?.address));
        return navigate(MODULE_PATH.ADDRESS.PICKUP_DETAILS, { state: { showAutoComplete: true } });
      }
    }

    navigate(MODULE_PATH.ADDRESS.SELECT_ADDRESS);
  };

  const CardTitle = () => {
    if (pickUpTime === PICK_CARD.NOW) {
      return (
        <span onClick={() => setScheduleDrawer(true)}>
          <FontAwesomeIcon icon={solid('bolt')} />
          <b>Pickup Now</b> (As soon as possible)
        </span>
      );
    }
    return (
      <span onClick={() => setScheduleDrawer(true)}>
        <img src={SCHEDULE.CLOCK} alt="clock" />
        <b>Pickup Later </b> <span>({`${moment(scheduledDateTime).format(DAY_MMM_D_HH_MM)}`})</span>
      </span>
    );
  };

  const preventHandler = e => {
    e.stopPropagation();
  };

  return (
    <>
      <Card
        title={<CardTitle />}
        extra={
          <span className="pickupNow-angledown">
            <FontAwesomeIcon icon={solid('angle-down')} />
          </span>
        }
        className="pickup__dropoff"
      >
        <div className="form__input">
          <div className="map-marker" onClick={e => preventHandler(e)}>
            <img src={startMapMarker} alt="end-map-marker" />
            <div className="vertical-line"></div>
            <img src={endMapMarker} alt="start-map-marker" />
          </div>
          <div className="input-field">
            <input
              className="pickup-input"
              placeholder="Pickup"
              onClick={() => onClickPickUpDropOff('PickUp')}
              defaultValue=""
              value={pickUp?.senderDetails?.fullName || ''}
            />
            <input
              className="dropoff-input"
              placeholder="Drop-off"
              defaultValue=""
              value={dropOff?.recipientDetails?.fullName || ''}
              onClick={() => onClickPickUpDropOff('DropOff')}
            />
          </div>
        </div>
      </Card>
      <Schedule show={showScheduleDrawer} setScheduleDrawer={() => setScheduleDrawer(false)} />
    </>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPointLocation,
    },
    dispatch
  );

const mapStateToProps = state => ({
  pointLocation: state.onDemandReducer.pointLocation,
  view: state.homeReducer.view,
  dropOff: state.onDemandReducer.dropOff,
  orderDetails: state.homeReducer.targetOrder,
});

export default connect(mapStateToProps, matchDispatchToProps)(PickupDropOff);
