import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MODULE_PATH } from '../../../shared/constants/Module';
import PaymentDetails from '../../../shared/components/PaymentDetails';

import StickyHeader from '../../../shared/components/StickyHeader';
import { CONVENIENCE_FEE } from '../../../shared/utils/enums/DeliveryConstants';
import { formatAmount, formatBills } from '../../../shared/utils/helpers/purefunctions';

const PaymentBreakdown = () => {
  const [paymentDetails, setPaymentDetails] = useState({});

  const orderDetails = useSelector(state => state.homeReducer.targetOrder);

  const { paymentAmount, cashbackPaymentDetails } = orderDetails.gcash;

  const convenienceFee = CONVENIENCE_FEE.REGULAR;
  const voucherAmount = paymentAmount?.voucherAmount || 0;
  const tippingAmount = orderDetails.gcash?.tippingAmount || 0;

  const formatShippingFee = () => {
    const tempAmount = Number(formatAmount(paymentAmount?.value));
    const shippingAmount = tempAmount - 5;
    const [tempValue, decimal] = String(shippingAmount).split('.');

    if (String(shippingAmount).length === 2) {
      const [tempValue, decimal] = String(shippingAmount.toFixed(2)).split('.');
      if (Number(tempValue) >= 4) {
        return `${Number(tempValue).toLocaleString()}.${decimal}`;
      }
      return formatBills(shippingAmount);
    }

    if (Number(tempValue) >= 4) {
      if (!decimal) return `${Number(tempValue)}.00`;
      return `${Number(tempValue).toLocaleString()}.${decimal}`;
    }
  };

  const formattedAmount = formatAmount(paymentAmount?.value);

  const cashbackDeduction = isNaN(cashbackPaymentDetails?.cashbackDeduction)
    ? '0'
    : cashbackPaymentDetails?.cashbackDeduction;

  useEffect(() => {
    if (
      orderDetails.gcash.hasOwnProperty('cashbackPaymentDetails') &&
      cashbackPaymentDetails.isCashback === true
    ) {
      setPaymentDetails({
        finalShippingFee: cashbackPaymentDetails?.finalShippingFee,
        cashbackDeduction,
        totalAmount: cashbackPaymentDetails?.paymentAmount,
      });
    } else {
      setPaymentDetails({
        finalShippingFee: formatShippingFee(),
        totalAmount: formattedAmount,
      });
    }
  }, []);

  const prevNavigate = {
    label: 'Payment Breakdown',
    imgPath: '',
    path: MODULE_PATH.ORDERS.ORDER_DETAILS,
  };
  return (
    <>
      <div className="payment-breakdown-container">
        <div className="payment-breakdown-content">
          <StickyHeader title="Payment Breakdown" onPrevNavigate={prevNavigate} />
          <div className="body-content">
            <PaymentDetails
              totalAmount={paymentDetails?.totalAmount}
              shippingFee={paymentDetails?.finalShippingFee}
              convenienceFee={convenienceFee}
              tip={tippingAmount}
              cashBack={cashbackDeduction}
              voucherAmount={voucherAmount}
              isPaymentBreakdownPage={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentBreakdown;
