import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProduct } from '../../../../shared/redux/standard/actions';
import kgImage from '../../../../shared/assets/images/OndemandDelivery/kg.svg';
import arrowRight from '../../../../shared/assets/images/OndemandDelivery/arrow_right.svg';
import boxImage from '../../../../shared/assets/images/OndemandDelivery/box.png';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { STANDARD_ICON } from '../../../../shared/utils/enums/AppIcons';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import {
  COURIER_PRODUCT_SIZES,
  PRODUCT_SIZES,
} from '../../../../shared/utils/enums/StandardDelivery';
import { setPrevRoute } from '../../../../shared/redux/home/actions';
import { setCourier } from '../../../../shared/redux/standard/actions';

const ProductList = ({ setProduct, setPrevRoute }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const standardProps = useSelector(state => state.standardReducer);
  const { product } = standardProps;

  const prevNavigate = {
    label: 'Choose Product Type',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };
  const ProductIcon = ({ value }) => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return <img src={STANDARD_ICON[productIcon]} alt={value} />;
  };

  const handleSelectProduct = data => {
    const isProductSizeChange = data.value !== product.value;
    if (isProductSizeChange) {
      setProduct(data);
      dispatch(setCourier({}));
    } else {
      setProduct(data);
    }
    setPrevRoute('productSize');
    navigate(MODULE_PATH.HOME, {
      state: { isClicked: true, isProductSizeChange: isProductSizeChange },
    });
  };

  return (
    <div className="product__container">
      <StickyHeader title="Choose Product Size" onPrevNavigate={prevNavigate} />
      <span className="list__header v4">Available Product Sizes</span>
      {PRODUCT_SIZES.map((data, index) => (
        <div className="product__list" key={index}>
          <div className="info" onClick={() => handleSelectProduct(data)}>
            <ProductIcon value={data.value} />
            <div className="product-info">
              <div className="header">{data.value}</div>
              <div className="details">
                <span>
                  <img src={kgImage} alt="kg" /> {data.description.weightText}
                </span>
                <span>
                  <img src={boxImage} alt="bag" /> {data.description.dimensionText}
                </span>
              </div>
            </div>
            <span className="arrow-right">
              <img src={arrowRight} alt="arrow-right" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProduct,
      setPrevRoute,
    },
    dispatch
  );

export default connect(null, matchDispatchToProps)(ProductList);
