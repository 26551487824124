import React, { createContext, useState } from 'react';

const ItemRateContext = createContext();

export const InputItemRate = ({ children }) => {
  const [itemPrice, setItemPrice] = useState(1);

  return (
    <ItemRateContext.Provider value={{ itemPrice, setItemPrice }}>
      {children}
    </ItemRateContext.Provider>
  );
};

export default ItemRateContext;
