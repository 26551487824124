import React from 'react';

const PrivacyPolicyContents = ({contents}) =>  ( 
    contents.map((content, index) => (
        <div key={index} className='text'>
           <b>{content[0].title}</b>
           <div>
                {
                    Object.values(content[1]).map((data, key) => (
                        <>
                            <div key={key} className='data-content'>{data}</div><br/>
                        </>
                    ))
                }
            </div>
        </div>
    ))
)

export default PrivacyPolicyContents