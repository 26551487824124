import React from 'react';
import MaintenanceImg from '../assets/images/Maintenance/maintenance.png';
import ClickCloseIcon from '../assets/svg/click-close-icon.svg';

const MaintenancePage = () => {
  return (
    <div className="maintenance-container">
      <div className="content">
        <img src={MaintenanceImg} alt="Maintenance Img" className="maintenance-img" />
        <div className="bold-text">We're making some improvements!</div>
        <div className="text">
          We're making exciting updates to enhance your experience. We appreciate your patience.
          We'll be back soon to serve seamlessly.
        </div>
        <div className="tap">
          <p>Please tap the</p>
          <img src={ClickCloseIcon} alt="close icon" />
          <p>to close the app.</p>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
