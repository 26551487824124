import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

import { Drawer, Space, Button, TimePicker, DatePicker, message, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from '../../../../shared/components/SubmitButton';
import { SCHEDULE } from '../../../../shared/utils/enums/AppIcons';
import { PICK_CARD } from '../../../../shared/constants/OnDemand';
import { setPrevRoute } from '../../../../shared/redux/home/actions';
import {
  DAY_MMM_D_YYYY,
  H_MM_A,
  H_MM_a,
  KEEN_DATE,
  YYYY_MM_DD_H_MM_A,
  DAY_MMM_D,
  HH_MM,
} from '../../../../shared/utils/enums/dateFormats';
import { setTimeInterval, getServerTime } from '../../../../shared/utils/helpers/purefunctions';
import {
  setPickupDateTime,
  setPickUpTime,
  setPickedUp,
  setTimeTag,
} from '../../../../shared/redux/onDemand/actions';

const Schedule = ({ show, setScheduleDrawer, onSetVehicle }) => {
  const dispatch = useDispatch();
  const scheduledDateTime = useSelector(state => state.onDemandReducer.pickupDateTime);
  const pickUpTime = useSelector(state => state.onDemandReducer.pickUpTime);

  const [defaultTime, setDefaultTime] = useState(moment());
  const [scheduleDateTime, setScheduleDateTime] = useState(moment());
  const [time, setTime] = useState(null);
  const [noToBackDating, setNoToBackDating] = useState(true);

  const [timeSched, setTimeSched] = useState(PICK_CARD.NOW);

  const [isOpen, setIsOpen] = useState(false);
  // SPRINT3
  const initialDrawer = 200;
  const fullDrawer = 530;
  const flowDrawer = 400;
  const [drawerHeight, setDrawerHeight] = useState(initialDrawer);

  useEffect(() => {
    if (show) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    setCurrentDateAndTime();
    setTimeSched(pickUpTime);
    const pickLater = pickUpTime === PICK_CARD.LATER ? flowDrawer : initialDrawer;
    setDrawerHeight(pickLater);
  }, [show]);

  const setCurrentDateAndTime = async () => {
    let currDateTime = await getServerTime();
    let dateTime = setTimeInterval(currDateTime);
    setDefaultTime(dateTime);
    if (scheduledDateTime) {
      dateTime = moment(scheduledDateTime);
      setScheduleDateTime(dateTime);
    }
    setTime(moment(dateTime, 'x').format(H_MM_A));
  };

  useEffect(() => {
    if (!time) {
      return;
    }

    if (!isOpen) {
      const pickLater = timeSched === PICK_CARD.LATER ? flowDrawer : initialDrawer;
      setDrawerHeight(pickLater);
      setTimeSched(timeSched);
      setPickUpTime(timeSched);
    }
  }, [timeSched]);

  const handleChangeDate = action => {
    if (action === 'subtract' && noToBackDating) return;
    const newPickUpDate = moment(scheduleDateTime)[action](1, 'day');
    setNoToBackDating(
      moment(newPickUpDate.format(KEEN_DATE)).diff(moment(defaultTime).format(KEEN_DATE)) <= 0
    );
    setScheduleDateTime(newPickUpDate);
  };

  const onChangeTime = (time, timeString) => {
    const getSelectedTime = moment(
      `${moment(scheduleDateTime).format(KEEN_DATE)} ${timeString}`,
      YYYY_MM_DD_H_MM_A
    );

    setTime(moment(getSelectedTime, 'x').format(H_MM_A));
  };

  const handleTimePickerOpenChange = status => {
    setDrawerHeight(status ? fullDrawer : flowDrawer);
    setIsOpen(status);
  };

  const onSetPickUpTime = async(timeSched) => {
    const getSelectedDateTime = moment(
      `${moment(scheduleDateTime).format(KEEN_DATE)} ${time}`,
      YYYY_MM_DD_H_MM_A
    );

    let timeDiff = moment(defaultTime).diff(getSelectedDateTime)
    let currDateTime = await getServerTime();
    if (getSelectedDateTime.isSameOrBefore(currDateTime)) {
      return message.info('Past Date and Time is not allowed', 3.0);
    }

    if (timeSched === PICK_CARD.LATER) {
      if (timeDiff > 0) {
        return message.info(`Please select a later time slot. It's too close to the next available time.`, 3.0);
      }
    }


    dispatch(setPickupDateTime(getSelectedDateTime.valueOf()));
    dispatch(setPickUpTime(timeSched));
    dispatch(setPickedUp(true));

    onSetVehicle();
    handleSetSchedulerDrawer();
  };

  const setPickNow = () => {
    dispatch(setTimeTag(undefined));
    dispatch(setPrevRoute('PickUpNowLater'));
    onSetPickUpTime(PICK_CARD.NOW);
  };

  const setPickLater = () => {
    dispatch(setTimeTag(undefined));
    dispatch(setPrevRoute('PickUpNowLater'));
    onSetPickUpTime(PICK_CARD.LATER);
  };

  const handleSetSchedulerDrawer = () => {
    setTimeSched(timeSched);
    setScheduleDrawer();
  };

  let pickSelected = 'pick__schedule';
  let pickUpTimer = 'pickup__timepicker';
  if (timeSched === PICK_CARD.LATER) {
    pickSelected = `${pickSelected} selected`;
  }
  if (true) {
    pickSelected = `${pickSelected} slide`;
    pickUpTimer = `${pickUpTimer} slide`;
  }

  const pickerTime = dayjs(
    moment(`${scheduleDateTime.format(DAY_MMM_D)}, ${time}`).format(HH_MM),
    HH_MM
  );

  const handleTimePickerClose = () => {
    setDrawerHeight(flowDrawer);
  };

  return (
    <Drawer
      title="Pickup Time"
      placement="bottom"
      height={`${drawerHeight}px`}
      className={`schedule__time ${show ? 'drawer-open' : 'drawer-closed'}`}
      onClose={() => handleSetSchedulerDrawer()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => handleSetSchedulerDrawer()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="schedule__time__container">
        <div
          className={`pick__schedule ${timeSched === PICK_CARD.NOW ? 'selected' : ''}`}
          onClick={() => setPickNow()}
        >
          <span className="bolt">
            <FontAwesomeIcon icon={solid('bolt')} />
          </span>
          <span>
            Pickup Now <span>(As soon as possible)</span>
          </span>
          <span className="selected__icon">
            <img src={SCHEDULE.SELECTED} alt="selected" />
          </span>
        </div>
        <div className={pickSelected} onClick={() => setTimeSched(PICK_CARD.LATER)}>
          {time ? (
            <div className="schedule__picklater__heading">
              <span>
                <img src={SCHEDULE.CLOCK} alt="clock" />
              </span>
              <span>
                Pickup Later{' '}
                <span className="display-time-later">
                  ({`${scheduleDateTime.format(DAY_MMM_D)}, ${time}`})
                </span>
              </span>
              <span className="selected__icon">
                <img src={SCHEDULE.SELECTED} alt="selected" />
              </span>
            </div>
          ) : (
            <Spin />
          )}
          <div className="schedule__date_time">
            <div className="schedule__date">
              <FontAwesomeIcon
                className={`schedule__date__nav left ${noToBackDating}`}
                icon={solid('chevron-left')}
                onClick={() => handleChangeDate('subtract')}
              />
              <div className="schedule__date__picker">
                <DatePicker
                  size={'large'}
                  defaultValue={scheduleDateTime}
                  format={DAY_MMM_D_YYYY}
                  value={scheduleDateTime}
                  allowClear={false}
                  disabled
                />
              </div>
              <FontAwesomeIcon
                className="schedule__date__nav right"
                icon={solid('chevron-right')}
                onClick={() => handleChangeDate('add')}
              />
            </div>
            <div className="time__container">
              <TimePicker
                className={pickUpTimer}
                value={pickerTime}
                use12Hours
                format={H_MM_a}
                minuteStep={15}
                onChange={onChangeTime}
                inputReadOnly={true}
                onOpenChange={handleTimePickerOpenChange}
                getPopupContainer={trigger => trigger.parentNode}
                onClose={handleTimePickerClose}
              />
            </div>
            <div className="time__container__submit">
              <SubmitButton
                isProceedValue={!isOpen}
                name="Confirm Schedule"
                onClick={() => setPickLater()}
              />
            </div>
            {/* 
            // SPRINT3
            <div className='time__container__submit' >
              <SubmitButton
                isProceedValue={!isOpen}
                name="Confirm Schedule"
                onClick={() => onSetPickUpTime()}
              />
            </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default Schedule;
