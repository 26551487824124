export const CANCEL_ERROR_MESSAGE = [
  'Unable to cancel order',
  'Sorry, we are unable to cancel this order. Refund will be applied once order is successfully cancelled.',
];

export const VOUCHER_ERROR_MESSAGE = 'Voucher Error, Please try again.';

export const PRODUCT_SIZE_NOT_SUPPORTED = 'Product size not supported';

export const DPH_API_ERROR_MESSAGE = [
  'Invalid Session. Check your Auth-Token.',
  'Missing access token',
  'Session expired. Renew your Auth-Token.',
  'Request failed with status code 409',
];
