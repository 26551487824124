import React from 'react';
import { connect } from 'react-redux';
import OrderLoader from '../../../../shared/elements/Loader/OrderLoader';
import BookingForm from '../../../../shared/components/BookingForm';

const Orders = ({ fetched }) => {

  const Repeat = ({ numberOfTimes = 0, children }) => [...Array(numberOfTimes)].map(() => children);
  return (
    <>
      {!fetched ? (
        <Repeat numberOfTimes={3}>
          <OrderLoader />
        </Repeat>
      ) : (
        <BookingForm />
      )}
    </>
  );
};
const mapStateToProps = state => ({
  fetched: state.homeReducer.fetch,
});
export default connect(mapStateToProps)(Orders);
