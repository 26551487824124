import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentGif from '../../../shared/assets/images/Process/success-animation.gif';
import PaymentFailed from '../../../shared/assets/images/Process/payment-internal-error.png';
import splashBackground from '../../../shared/assets/images/SplashScreen/splash-background.png';
import PaymentSummaryBtn from '../../../shared/components/PaymentSummaryBtn';
import { setLoader } from '../../../shared/redux/home/actions';
import { setTargetOrder } from '../../../shared/redux/home/actions';
import { MODULE_PATH } from '../../../shared/constants/Module';
import OrderPlaced from '../../../shared/components/v2/OrderPlaced';
import { message } from 'antd';

const Payment = ({ setTargetOrder, userInfo }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const isOrderCreated = location?.state?.status;

  const styles = {
    cover: {
      backgroundImage: `url(${splashBackground})`,
      backgroundSize: 'cover',
      height: '100vh',
    },
    container: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      width: '44.445%',
    },
    logo: {
      width: '100%',
    },
  };

  const DisplayImage = () => {
    return <img src={PaymentFailed} alt="Payment Failed" className="process-logo" />;
  };

  const DisplayText = () => {
    return (
      <>
        <div className="payment-info">
          Sorry, we are unable to process your payment at this time.
        </div>
        <div className="payment-info">
          Looks like we're having an internal issue processing your payment. Please try again.
        </div>
      </>
    );
  };

  const handleReturnHome = () => {
    navigate(MODULE_PATH.HOME);
  };

  return (
    <>
      {isOrderCreated ? (
        <OrderPlaced isFoodPandaCourier={location?.state?.isFoodPandaCourier} />
      ) : (
        <div style={styles.cover} className="about-us-container">
          <div className="about-us-content">
            <div className="body-content-payment">
              <div className="parcels-payment">
                <DisplayImage />
                <DisplayText />
                <div className="form-container">
                  <PaymentSummaryBtn
                    proceedPaymentSummary={true}
                    onClick={handleReturnHome}
                    name="Back to Home"
                    className="payment-result"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setTargetOrder,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  userInfo: state.usersReducer.currentUser,
});
export default connect(mapStateToProps, matchDispatchToProps)(Payment);
