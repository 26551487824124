import produce from 'immer';
import { PAGE } from '../../constants/Module';
import {
  SET_FORM_VIEW,
  SET_LOADER,
  SET_FETCH,
  SET_FETCH_ALL,
  SET_TARGET_ORDER,
  SET_ORDER_LIST,
  SET_ORDER_LIST_MONGO,
  SET_FOR_REFUND_ORDER_LIST,
  SET_CANCELLED_ORDER_LIST,
  SET_COMPLETED_ORDER_LIST,
  SET_TOOL_TIP,
  SET_FORM_TAB,
  SET_PREV_ROUTE,
  SET_CASHBACK_TRANSACTIONS_LIST,
} from '../enums/types';

const initialState = {
  view: PAGE.HOME,
  loader: false,
  fetch: false,
  fetchAll: false,
  tooltip: false,
  targetOrder: {},
  orderList: [],
  orderListMongo: [],
  forRefundOrders: [],
  cancelledOrders: [],
  completedOrderList: [],
  isEligible: false,
  isBookAnyUser: true,
  formTab: 'onDemand-view',
  prevRoute: '',
  cashbackTransactions: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_FORM_VIEW:
        draft.view = action.payload;
        break;
      case SET_LOADER:
        draft.loader = action.payload;
        break;
      case SET_FETCH:
        draft.fetch = action.payload;
        break;
      case SET_FETCH_ALL:
        draft.fetchAll = action.payload;
        break;
      case SET_TARGET_ORDER:
        draft.targetOrder = action.payload;
        break;
      case SET_ORDER_LIST:
        draft.orderList = action.payload;
        break;
      case SET_FOR_REFUND_ORDER_LIST:
        draft.forRefundOrders = action.payload;
        break;
      case SET_CANCELLED_ORDER_LIST:
        draft.cancelledOrders = action.payload;
        break;
      case SET_COMPLETED_ORDER_LIST:
        draft.completedOrderList = action.payload;
        break;
      case SET_ORDER_LIST_MONGO:
        draft.orderListMongo = action.payload;
        break;
      case SET_TOOL_TIP:
        draft.tooltip = action.payload;
        break;
      case SET_FORM_TAB:
        draft.formTab = action.payload;
        break;
      case SET_PREV_ROUTE:
        draft.prevRoute = action.payload;
        break;
      case SET_CASHBACK_TRANSACTIONS_LIST:
        draft.cashbackTransactions = action.payload;
        break;
      default:
        return draft;
    }
  });
