/* global my */
import { get } from 'lodash';
import { Button, message } from 'antd';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import OrdersDAO from '../../../../shared/utils/dao/Orders';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ArrowLeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { copyToClipBoard } from '../../../../shared/utils/helpers/purefunctions';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { GCASH_APP_ID } from '../../../../shared/utils/enums/AppConstants';

const OrderHistory = () => {
  let Trail = useRef(null);

  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [trail, setTrail] = useState([]);

  const orderDetails = useSelector(state => state.homeReducer.targetOrder);
  const postId = get(orderDetails, 'postId');
  const meta = get(orderDetails, 'meta');

  const deliveryDetails = JSON.parse(meta);
  const trackingNumber = get(deliveryDetails, 'trackingNumber');
  const trackingUrl = get(deliveryDetails, 'trackingUrl');

  const handleClick = async () => {
    if (!trackingUrl) {
      message.error('Order has no tracking URL', 3.0);
      return;
    }
    const result = await my.postMessage({ url: trackingUrl, GCASH_APP_ID });
    return result;
  };

  const getTrail = useCallback(async () => {
    Trail.current = new OrdersDAO();
    const trailData = await Trail.current.getOrderTrailOnce(postId);
    setTrail(trailData);
  }, [postId]);

  useEffect(() => {
    getTrail();
  }, [getTrail]);

  const SeeMore = ({ trail }) => {
    const SeeMoreBtn = () => (
      <div>
        See More <DownOutlined style={{ marginTop: '5px' }} />
      </div>
    );
    const SeeLessBtn = () => (
      <div>
        See Less <UpOutlined />
      </div>
    );
    return (
      <div className="show-more-btn" onClick={() => setShowMore(!showMore)}>
        {showMore ? trail : trail.slice(0, 5)}
        <div className="show-more-data">{showMore ? <SeeLessBtn /> : <SeeMoreBtn />}</div>
      </div>
    );
  };

  const removeVerticalLine = index => (!showMore && index === 4 ? 'remove' : '');
  const lastTrail = index => (showMore && index === trail.length - 1 ? 'last-child' : '');

  const TimeLine = ({ trail }) => {
    return (
      <div>
        {trail.length <= 5 ? (
          trail.map((data, index) => (
            <div className={`info__card ${index} ${lastTrail(index)}`} key={index}>
              <div className={`circle-${index}`}></div>
              <div className={`vertical-line`}></div>
              <div className={`time-${index}`}>
                {moment(data.timeStamp).format('hh:mm A MMM DD')}
              </div>
              <div className={`action-${index}`}>{`${data.action} ${data.business}`}</div>
              <div className={`remarks-${index}`}>{data?.remarks || ''}</div>
            </div>
          ))
        ) : (
          <SeeMore
            trail={trail.map((data, index) => (
              <div className={`info__card ${index}`} key={index}>
                <div className={`circle-${index}`}></div>
                <div
                  className={`vertical-line ${removeVerticalLine(index)} ${lastTrail(index)}`}
                ></div>
                <div className={`time-${index}`}>
                  {moment(data.timeStamp).format('hh:mm A MMM DD')}
                </div>
                <div className={`action-${index}`}>{`${data.action} ${data.business}`}</div>
                <div className={`remarks-${index}`}>{data?.remarks || ''}</div>
              </div>
            ))}
          />
        )}
      </div>
    );
  };

  return (
    <div className="tracking__history">
      <div className="header">
        <Button
          className="btn-return"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, { state: { link: '/' } })}
        />
        <div className="title">Tracking History</div>
      </div>
      <div className="body">
        <div className="tracking__card">
          <div>Tracking No.</div>
          <div>{trackingNumber}</div>
          <div onClick={() => copyToClipBoard(trackingNumber)}>copy</div>
          <div>Tracking URL</div>
          <div>{trackingUrl}</div>
          <div onClick={handleClick} style={{ color: '#3250D6' }}>
            open
          </div>
        </div>
        <div className="trail__card">{<TimeLine trail={trail} /> || 'Loading...'}</div>
      </div>
    </div>
  );
};
export default OrderHistory;
