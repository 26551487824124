import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import MAIL from '../../../../shared/assets/svg/mail.svg'
import TICKET from '../../../../shared/assets/svg/ticket.svg'
import {
  copyToClipBoard,
  showConfirmationModal,
} from '../../../../shared/utils/helpers/purefunctions';
import { SUPPORT_CONTACTS } from '../../../../shared/utils/enums/AppConstants';

const HelpCenter = () => {
  const { lastmileEmail, submitTicket,  helpCenter, chatSupportLink } = SUPPORT_CONTACTS;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const prevNavigate = {
    label: 'Choose Vehicle Type',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };
  const handleOpenLink = url => {
    showConfirmationModal(url);
  };

  return (
    <div className="help-center-container">
      <StickyHeader title="Help Center" onPrevNavigate={prevNavigate} />
      <div className='card'>
        <span className='title'>How can we help you?</span>
        <span className='description'>Report your concerns by leaving a message and we’ll respond as soon as we can.</span>
        <div className='help-mail' onClick={() => copyToClipBoard(lastmileEmail)}>
          <img src={MAIL} />
          <span>help@lastmile.ph</span>
        </div>
        <span className='help-concern'>You can also talk with out customer support to ask or follow-up on your concerns</span>
        <div className='chat-live-support' onClick={() => handleOpenLink(chatSupportLink)}><span>Chat with Live Support</span></div>
        <div className='visit-help-center' onClick={() => handleOpenLink(helpCenter)}><span>Visit our Help Center</span></div>
      </div>
      <div className='card'>
        <span className='title'>Your Tickets</span>
        <div className='follow-updates'>
          <img src={TICKET} />
          <span>You can follow up and get updates on your active tickets here.</span>
        </div>
        <div className='chat-live-support' onClick={() => handleOpenLink(submitTicket)}><span>Submit a ticket</span></div>
      </div>
    </div>
  );
};

export default HelpCenter;
