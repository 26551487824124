import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../constants/Module';

const TrackOrderStandard = ({ trail }) => {
  const navigate = useNavigate();

  const [trailData] = trail;
  const trailAction =
    trailData?.action && trailData?.business && `${trailData?.action} ${trailData?.business} `;

  return (
    <div className="standard__track__order">
      <div className="content">
        <div className="row-1x">
          <div className="circle"></div>
          <div className="time">
            {moment(trailData?.timeStamp).format('hh:mm A MMM DD') || (
              <Skeleton.Input block={true} active={true} size={'small'} />
            )}
          </div>
          <div className="status-header">
            {trailAction || <Skeleton.Input block={true} active={true} size={'small'} />}
          </div>
          <div className="status-sub-header">{trailData?.remarks || ''}</div>
        </div>
        <div className="row-2x">
          <div className="view-more">View More</div>
          <Button
            className="btn-view-more"
            icon={<ArrowRightOutlined />}
            onClick={() => navigate(MODULE_PATH.STANDARD.ORDER_HISTORY)}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackOrderStandard;
