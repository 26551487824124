export const COURIER_VEHICLES = {
  GRAB: {
    Vehicles: {
      Bike: 'MOTORCYCLE',
      CAR: 'SEDAN',
    },
  },
  MRSPEEDY: {
    Vehicles: {
      8: 'MOTORCYCLE',
      7: 'SEDAN',
    },
  },
  LALAMOVE: {
    Vehicles: {
      MOTORCYCLE: 'MOTORCYCLE',
      MPV: 'MPV',
      VAN1000: 'VAN_L300',
      SEDAN: 'SEDAN',
      VAN: 'VAN_MULTICAB',
      TRUCK550: 'TRUCK',
      LD_10WHEEL_TRUCK: 'LD_10W_TRUCK',
      '10WHEEL_TRUCK': 'TEN_WHEELER_TRUCK',
      TRUCK330: 'TRUCK330',
    },
  },
  TRANSPORTIFY: {
    Vehicles: {
      ECONOMY: 'SEDAN',
      L300: 'VAN_L300',
      'pickup truck': 'PICKUP_TRUCK',
      'long pickup': 'LONG_PICKUP_TRUCK',
      'economy extra space': 'MPV',
      'light van': 'VAN_MULTICAB',
      'canopy multicab': 'CANOPY_MULTICAB',
      'small pickup': 'SMALL_PICKUP',
      'closed van extra space': 'CLOSED_VAN_XS',
      'closed van': 'CLOSED_VAN',
      'pickup truck extra space': 'PICKUP_TRUCK_XS',
      'mini dump truck': 'MINI_DUMP_TRUCK',
      'wing van (40ft)': 'WING_VAN_40',
      'long pickup truck': 'SIX_WHEELER_FORWARD_TRUCK_XS',
      '6w fwd truck': 'SIX_WHEELER_FORWARD_TRUCK',
      VAN: 'TRUCK330',
      '10w wing van': 'TEN_WHEELER_WING_VAN_32',
    },
  },
  PANDAGO: {
    Vehicles: {
      MOTORCYCLE: 'MOTORCYCLE',
    },
  },
};

export const PROVIDERS_HOTLINE = {
  MRSPEEDY: '(02)123456789',
  LALAMOVE: '(02)234567891',
  GRAB: '(02)345678912',
};

export const MRSPEEDY_VEHICLE_TYPE = {
  7: 'Car',
  8: 'Motorcycle',
};

export const COURIER_VEHICLE_TYPES = {
  motorcycle: 'MOTORCYCLE',
  sedan: 'SEDAN',
  mpv: 'SEDAN',
  van_l300: 'L300',
  van_multicab: 'L300',
  truck: 'TRUCK',
  truck330: 'TRUCK',
  ld_10w_truck: 'TRUCK',
  suv: 'SUV',
  pickup_truck: 'PICKUP_TRUCK',
};

export const MRSPEEDY = 'MRSPEEDY';
export const BORZO = 'BORZO';
export const PANDAGO = 'Pandago';
export const FOOD_PANDA = 'FOOD_PANDA';
