import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { setVoucherCode } from '../../../shared/redux/onDemand/actions';
import IconVoucher from '../../../shared/assets/svg/home-booking/icon_voucher.svg';
import checkIcon from '../../../shared/assets/svg/checkIcon.svg';
import LoaderSpinner from '../../../shared/components/LoaderSpinner';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import { formatBills } from '../../../shared/utils/helpers/purefunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OFFER_TYPES, ParcelsPH } from '../../../shared/utils/enums/AppConstants';

const Voucher = ({
  setVoucherAmount,
  shippingFee,
  voucherAmount,
  userInfo,
  setVoucherCode,
  isBookAny,
  provider,
  refNo,
  isVoucherValid,
}) => {
  const [voucher, setVoucher] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [check, setCheck] = useState(null);
  const [validatedCode, setValidatedCode] = useState(null);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleOnApplyVoucher = async () => {
    const inquireVoucherPayload = {
      userId: userInfo.currentUser.id,
      voucherCode: voucher,
      recordCreated: userInfo.currentUser?.recordCreated || false,
      shippingFee: shippingFee,
      isBroadCast: isBookAny,
      provider: provider,
      refNo: refNo,
      productName: ParcelsPH,
    };

    const paymentDao = new PaymentDAO();
    const voucherResponse = await paymentDao.inquireVoucher(inquireVoucherPayload);

    setLoading(false);
    setValidatedCode(voucher);

    const { success, message, data = {} } = voucherResponse;

    if (success) {
      setVoucherCode(voucher);
      if (!data?.offerType || data?.offerType === OFFER_TYPES.DISCOUNT) {
        const discountAmount = data?.percentage
          ? shippingFee * parseFloat(data.percentage / 100)
          : data.amount;
        let newAmount = discountAmount;
        console.log(discountAmount, data, shippingFee);

        // if (data?.percent && data?.cappedAmount && data.cappedAmount > 0 && discountAmount > data.cappedAmount) {
        if (data?.cappedAmount) {
          if (data.cappedAmount > 0 && discountAmount > data.cappedAmount) {
            newAmount = data.cappedAmount;
          }
        }

        // discountAmount is 73
        //cappedAmount is 100
        // if 73 > 100 get 100, since 73 is less than 100 then the discount is 73

        setTimeout(() => setCheck(true), 200);
        setTimeout(() => setIsValid(true), 200);
        setTimeout(() => setCheck(false), 700);
        setTimeout(() => setShowRemoveBtn(true), 700);
        setVoucherAmount(newAmount);
        setSuccessMessage(`Voucher code applied. Save P${formatBills(newAmount)} successfully.`);
      } else {
        setSuccessMessage(`Cashback voucher code successfully applied.`);
      }
    } else {
      setIsValid(false);
      setShowRemoveBtn(true);
      setErrorMessage(message);
      isVoucherValid(false);
    }
  };
  const handleInputOnchange = e => {
    setVoucher(e.target.value.toUpperCase());

    if (e.target.value !== validatedCode) {
      setIsValid(null);
      setCheck(null);
      setVoucherAmount(0);
      setShowRemoveBtn(false);
      setErrorMessage('');
      isVoucherValid(true);
      setVoucherCode('');
      setSuccessMessage('');
    }
  };
  const handleOnRemoveVoucher = () => {
    setVoucher('');
    setVoucherAmount(0);
    setIsValid(false);
    setValidatedCode('');
    setErrorMessage('');
    isVoucherValid(true);
    setVoucherCode('');
    setSuccessMessage('');
  };

  return (
    <>
      <div className={`voucher-main-container ${!errorMessage ? '' : 'error'}`}>
        <div className="voucher-input-container">
          <div className="voucher-icon">
            <img src={IconVoucher} alt="voucher-icon" />
          </div>
          <input
            className={`voucher-input ${isValid ?? ''}`}
            type="text"
            placeholder="Enter Voucher Code"
            onChange={e => handleInputOnchange(e)}
            value={voucher}
          />
        </div>
        <div className="voucher-button-container">
          {loading && (
            <div className="loader">
              <LoaderSpinner size="24px" />
            </div>
          )}
          {check && (
            <div className="loader">{isValid ? <img src={checkIcon} alt="check icon" /> : ''}</div>
          )}
          {!loading && !voucherAmount && !check && !errorMessage && (
            <Button
              className={`applyBtn ${!voucher ? 'disabled' : ''}`}
              onClick={() => handleOnApplyVoucher()}
              disabled={!voucher}
            >
              Apply
            </Button>
          )}
          {validatedCode && !loading && showRemoveBtn && (
            <Button
              className="removeBtn"
              onClick={() => {
                handleOnRemoveVoucher();
              }}
            >
              Remove
            </Button>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className="voucher-error">
          <FontAwesomeIcon icon={solid('exclamation-circle')} className="info-icon" />
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="voucher-success">
          <FontAwesomeIcon icon={solid('circle-info')} className="info-icon" />
          {successMessage}
        </div>
      )}
    </>
  );
};
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setVoucherCode,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  userInfo: state.usersReducer,
  onDemandProps: state.onDemandReducer,
});
export default connect(mapStateToProps, matchDispatchToProps)(Voucher);
