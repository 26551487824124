import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import StickyHeader from '../../../shared/components/StickyHeader';
import ItemDetails from '../../../shared/components/ItemDetails';
import PickupDropOff from './components/PickupDropOff';

import { ICON } from '../../../shared/utils/enums/AppIcons';
import Courier from './containers/Courier';
import ProductSize from './containers/ProductSize';
import { isEmpty } from 'lodash';
import FooterBtn from '../../../shared/components/FooterBtn';
import { MODULE_PATH } from '../../../shared/constants/Module';
import {
  generateOrderId,
  getServerTime,
  setTimeInterval,
} from '../../../shared/utils/helpers/purefunctions';
import {
  setDropOff,
  setOrderId,
  setPickUp,
  setPickupDateTime,
  setProduct,
  setStandardItemType,
} from '../../../shared/redux/standard/actions';
import { PRODUCT_SIZES } from '../../../shared/utils/enums/StandardDelivery';

function StandardDelivery() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFillUp, setIsFillUp] = useState(false);
  const [setServerTime, onSetServerTime] = useState(false);

  const targetOrder = useSelector(state => state.homeReducer.targetOrder);
  const { pickUp, dropOff, itemType, courier, orderId, pickupDateTime } = useSelector(
    state => state.standardReducer
  );

  const prevNavigate = { path: '/', state: {} };
  const nextNavigate = {
    label: 'Standard Delivery',
    imgPath: ICON.STANDARD,
    className: 'standard__header',
  };

  const {
    meta,
    gcash,
    status,
    province,
    pickupCity,
    barangay,
    customerName,
    contactNumber,
    pickupAddress,
  } = targetOrder;
  const deliveryDetails = meta ? JSON.parse(meta) : {};
  const [filterProduct] = PRODUCT_SIZES.filter(
    data => data.value.toLowerCase() === targetOrder?.product?.size
  );

  useEffect(() => {
    if (!isEmpty(targetOrder) && ['DONE', 'CANCELLED'].includes(status)) {
      dispatch(setStandardItemType(gcash.itemType));
      dispatch(setProduct(filterProduct));
      dispatch(
        setPickUp({
          barangay,
          province,
          city: pickupCity,
          address: pickupAddress,
          senderDetails: {
            province,
            barangay,
            city: pickupCity,
            address: pickupAddress,
            contactNo: contactNumber,
            fullName: customerName,
            unitFloorHouse: gcash.dropOffUnitFloor,
          },
        })
      );
      dispatch(
        setDropOff({
          province: deliveryDetails.province,
          city: deliveryDetails.deliveryCity,
          address: deliveryDetails.deliveryAddress,
          barangay: deliveryDetails.barangay,
          recipientDetails: {
            province: deliveryDetails.province,
            city: deliveryDetails.deliveryCity,
            barangay: deliveryDetails.barangay,
            address: deliveryDetails.deliveryAddress,
            contactNo: deliveryDetails.contactNumber,
            fullName: deliveryDetails.customerName,
            unitFloorHouse: gcash.pickupUnitFloor,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    setIsFillUp(
      [pickUp.senderDetails, dropOff.recipientDetails, itemType, courier].every(
        value => !isEmpty(value)
      )
    );
  }, [pickUp.senderDetails, dropOff.recipientDetails, itemType, courier]);

  useEffect(() => {
    if (orderId === '') {
      let num = 1;
      const set_OrderId = generateOrderId(num);
      dispatch(setOrderId(set_OrderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    !setServerTime && setCurrentDateAndTime();
  }, [setServerTime]);

  const setCurrentDateAndTime = async () => {
    const currDateTime = await getServerTime();
    const intervalPres = 300000;

    if (pickupDateTime && pickupDateTime % intervalPres === 0) {
      dispatch(setPickupDateTime(pickupDateTime));
    } else {
      const dateTime = setTimeInterval(currDateTime);
      dispatch(setPickupDateTime(dateTime));
    }
    onSetServerTime(true);
  };

  const ReviewButton = ({ label }) => {
    if (!isFillUp) {
      return (
        <div className="footer__btn disable">
          <div className="btn">{label}</div>
        </div>
      );
    }
    return (
      <FooterBtn label={label} onClick={() => navigate(MODULE_PATH.PAYMENT.PAYMENT_SUMMARY)} />
    );
  };

  return (
    <>
      <StickyHeader title="New Order" onPrevNavigate={prevNavigate} onNextNavigate={nextNavigate} />
      <div className="standard__delivery__container">
        <div className="content">
          <PickupDropOff />
          <ItemDetails />
          <ProductSize />
          <Courier />
        </div>
        <ReviewButton label="Review Order" />
      </div>
    </>
  );
}

export default StandardDelivery;
