import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import DevelopmentImg from '../../shared/assets/images/ComingSoon/development-vector.png';
import { MODULE_PATH } from '../constants/Module';

const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <img src={DevelopmentImg} alt="Development" className="development" />
        <div className="title">This feature is currently in development.</div>
        <div className="description">
          Standard delivery service will be available soon. Stay tuned for updates!
        </div>
        <div className="btn" onClick={() => navigate(MODULE_PATH.HOME)}>
          <Button className="btn-return" icon={<ArrowLeftOutlined />} />
          Back to My Orders
        </div>
      </div>
    </div>
  );
};
export default ComingSoon;
