import React from 'react';
import { MAPS_API, MAPS_API_KEY } from '../../utils/enums/AppConstants';

import { useSelector } from 'react-redux';

import AssignRiderLoader from '../../elements/Loader/AssignRiderLoader';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import StaticMap from '../StaticMap';
import { MODULE_PATH } from '../../constants/Module';
import { useNavigate } from 'react-router';

const SearchNearbyRiders = () => {
  const ondemandPickup = useSelector(state => state.onDemandReducer.pickUp);
  const navigate = useNavigate();

  const coordinates = {
    lat: ondemandPickup?.longitude,
    lng: ondemandPickup?.latitude,
  };

  const FooterLoader = () => {
    return (
      <div className="footer__loader">
        <AssignRiderLoader SearchingNearbyRiderClass="searching__nearby__riders" />
        <span>Searching nearby riders</span>
        <div className="countdown-loader">
          <span>Redirecting to Homepage</span>

          <CountdownCircleTimer
            isGrowing={false}
            strokeWidth={2}
            trailStrokeWidth={1}
            isSmoothColorTransition={true}
            size={25}
            isPlaying
            duration={5}
            colors={['#e94e75']}
            onComplete={() => {
              return navigate(MODULE_PATH.HOME);
            }}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  };

  const googleMapURL = `${MAPS_API}?key=${MAPS_API_KEY}&loading=async&region=PH`;

  return (
    <div className="search__riders__container">
      <StaticMap
        coordinates={coordinates}
        googleMapURL={googleMapURL}
        containerElement={<div style={{ height: `100vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        loadingElement={<div style={{ height: `100%` }} />}
      />
      <FooterLoader />
    </div>
  );
};

export default SearchNearbyRiders;
