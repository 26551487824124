import { doc, getDoc } from 'firebase/firestore';
import { firestoreDb } from '../helpers/firebaseUtils';

class AppInfoDAO {
  constructor() {
    this.docRef = doc(firestoreDb, 'GCASH/SETUP/INFO/CLIENT_INFO'); // Assuming firestoreDb is your Firestore instance
  }

  async fetchAppInfo() {
    const initInfo = {
      bannerOrder: [],
      version: "v3.0.0.0 (1.0.124) Build; Build 1.0.124"
    }
    try {
      const docSnap = await getDoc(this.docRef);

      if (docSnap.exists()) {
        return docSnap.data()?.appInfo || initInfo;
      }
      return initInfo
    } catch (error) {
      console.error('Error fetching app info:', error);
      return initInfo
    }
  }
}

export default AppInfoDAO;
