import { Button, message } from 'antd';
import React, { useEffect } from 'react';

import { ICON } from '../utils/enums/AppIcons';

const SubmitButton = ({
  name,
  onClick,
  isProceedValue,
  className,
  hasIcon,
  label,
  istimePickerStatus,
}) => {
  const handleSubmit = () => {
    if (label === 'new-address-book' && isProceedValue === false) {
      message.error('Input required fields.', 3.0);
      return;
    } else if (isProceedValue === false) {
      return;
    }
    onClick();
  };

  return (
    <Button
      className={`submit-button ${isProceedValue ? 'proceed' : ''} ${className || ''}`}
      onClick={handleSubmit}
    >
      {hasIcon && <img src={ICON[hasIcon]} alt="icon" />} {name}
    </Button>
  );
};

export default SubmitButton;
