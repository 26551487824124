import WeightSVG from '../../assets/images/Icons/weight.svg';
import DimensionSVG from '../../assets/images/Icons/dimension.svg';

export const COURIERS_LIST = ['GRAB', 'LALAMOVE', 'MRSPEEDY', 'PANDAGO'];
// ,'TRANSPORTIFY'];
export const COURIERS = ['GRAB', 'LALAMOVE', 'FOOD_PANDA'];
// export const TRANSPORTIFY_COURIER = ['TRANSPORTIFY'];

export const COURIER_LIST_PER_PROVIDER = {
  LALAMOVE: {
    VAN: 'VAN_MULTICAB',
  },
};

export const PRODUCT_SIZES = [
  {
    sizeName: 'Small (S)',
    value: 'Small',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to (0) Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '0x0x0 in',
    },
  },
  {
    sizeName: 'Medium (M)',
    value: 'Medium',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to (0) Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '0x0x0 in',
    },
  },
  {
    sizeName: 'Large (L)',
    value: 'Large',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to (0) Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '0x0x0 in',
    },
  },
  {
    sizeName: 'Extra Large (XL)',
    value: 'Extra Large',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to (0) Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '0x0x0 in',
    },
  },
];

export const vehicleTypeName = {
  Motorcycle: 'MOTORCYCLE',
  Car: 'CAR',
  Bike: 'BIKE',
  MPV: 'MPV',
  Van_l300: 'VAN_L300',
  Sedan: 'SEDAN',
  VanMulticab: 'VAN_MULTICAB',
  Truck: 'TRUCK',
  LD_10w_Truck: 'LD_10W_TRUCK',
  Ten_wheeler_truck: 'TEN_WHEELER_TRUCK',
  Truck330: 'TRUCK330',
  LD_10W_TRUCK: 'LD_10W_TRUCK',
  TEN_WHEELER_TRUCK: 'TEN_WHEELER_TRUCK',
  Van: 'VAN',
  VAN1000: 'VAN1000',
  '10WHEEL_TRUCK': '10WHEEL_TRUCK',
  LD_10WHEEL_TRUCK: 'LD_10WHEEL_TRUCK',
  Truck550: 'TRUCK550',
};

export const VEHICLE_TYPES = [
  {
    vehicleType: vehicleTypeName.Motorcycle,
    value: vehicleTypeName.Motorcycle,
    content: 'For small items like food, documents, small bags',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 15Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '14 x 14 x 10 in',
    },
  },
  {
    vehicleType: vehicleTypeName.Sedan,
    value: vehicleTypeName.Sedan,
    content: 'For small boxes, cakes, multiple parcels',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 200Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '38.4 x 22.8 x 27.6 in',
    },
  },
  {
    vehicleType: vehicleTypeName.MPV,
    value: vehicleTypeName.MPV,
    content: 'For large to medium boxes, multiple parcels',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 600Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '84 x 48 x 42 in',
    },
  },
  {
    vehicleType: vehicleTypeName.VanMulticab,
    value: vehicleTypeName.VanMulticab,
    content: 'For multiple boxes or piles of stocks',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 600Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '82.67 x47.24 x 47.24 in',
    },
  },
  {
    vehicleType: vehicleTypeName.Van_l300,
    value: vehicleTypeName.Van_l300,
    content: 'For multiple boxes or piles of stocks',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 1000Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '82.67 x 47.24 x 47.24 in',
    },
  },

  {
    vehicleType: vehicleTypeName.Truck330,
    value: vehicleTypeName.Truck330,
    content: 'For multiple sacked goods, furnitures, warehouse & cargo freight distribution',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 1000kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '82.67 x 47.24 x 47.24 in',
    },
  },
  {
    vehicleType: vehicleTypeName.Truck,
    value: vehicleTypeName.Truck,
    content: 'For multiple sacked goods, furnitures, warehouse & cargo freight distribution',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 2000Kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '106.29 x 66.92 x 66.92 in',
    },
  },
  {
    vehicleType: vehicleTypeName.LD_10w_Truck,
    value: vehicleTypeName.LD_10w_Truck,
    content: 'For multiple sacked goods, furnitures, warehouse & cargo freight distribution',
    description: {
      weightIcon: `${WeightSVG}`,
      weightText: 'Up to 12000kg',
      dimensionIcon: `${DimensionSVG}`,
      dimensionText: '393.70 x 94.48 x 90.55 in',
    },
  },
];

export const ITEM_TYPES = [
  'Electronics',
  'Document',
  'Food',
  'Medicine',
  'Furniture/Appliances',
  'Other (s)',
];

export const COURIER_VEHICLE_WEIGHT_SIZE = {
  BORZO: {
    [vehicleTypeName.Motorcycle]: {
      dimensionText: '20 x 15 x 20 in',
      serviceLimitation:
        'The maximum acceptable size of all Borzo items is 20in. (length) x 15in. (width) x 20in. (height) with a maximum weight of 20kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Sedan]: {
      dimensionText: '38.4 x 22.8 x 27.6 in',
      serviceLimitation:
        'The maximum acceptable size of all Borzo items is 48in. (length) x 38.4in. (width) x 33.6in. (height) with a maximum weight of 200kg. This is to ensure the safety of our delivery partners.',
    },
  },
  GRAB: {
    [vehicleTypeName.Bike]: {
      dimensionText: '10 x 10 x 10 in',
      serviceLimitation:
        'The maximum acceptable size of all Grab items is 10in. (length) x 10in. (width) x 10in. (height) with a maximum weight of 15kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Car]: {
      dimensionText: '15 x 15 x 12 in',
      serviceLimitation:
        'The maximum acceptable size of all Grab items is 15.74in. (length) x 15.74in. (width) x 12.59in. (height) with a maximum weight of 200kg. This is to ensure the safety of our delivery partners.',
    },
  },
  PANDAGO: {
    [vehicleTypeName.Motorcycle]: {
      dimensionText: '15.74 x 15.74 x 12.59 in',
      serviceLimitation:
        'The maximum acceptable size of all Pandago items is 15.74in. (length) x 15.74in. (width) x 12.59in. (height) with a maximum weight of 15kg. This is to ensure the safety of our delivery partners.',
    },
  },
  LALAMOVE: {
    [vehicleTypeName.Motorcycle]: {
      dimensionText: '19.2 x 15 x 19.2 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 19.2in. (length) x 15in. (width) x 19.2in. (height) with a maximum weight of 20kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.MPV]: {
      dimensionText: '84 x 48 x 42 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 84in. (length) x 48in. (width) x 42in. (height) with a maximum weight of 600kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.VAN1000]: {
      dimensionText: '82.67 x 47.24 x 47.24 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 82.67in. (length) x 47.24in. (width) x 47.24in. (height) with a maximum weight of 600kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Sedan]: {
      dimensionText: '39.37 x 23.62 x 27.55 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 39.37in. (length) x 23.62in. (width) x 27.55in. (height) with a maximum weight of 20kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Van]: {
      dimensionText: '82.67 x 47.24 x 47.24 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 82.67in. (length) x 47.24in. (width) x 47.24in. (height) with a maximum weight of 1000kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Truck550]: {
      dimensionText: '106.29 x 66.92 x 66.92 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 106.29in. (length) x 66.92in. (width) x 66.92in. (height) with a maximum weight of 200kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.LD_10WHEEL_TRUCK]: {
      dimensionText: '393.70 x 94.48 x 90.55 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 393.70in. (length) x 94.48in. (width) x 90.55in. (height) with a maximum weight of 600kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName['10WHEEL_TRUCK']]: {
      dimensionText: '393.70 x 94.48 x 90.55 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 393.70in. (length) x 94.48in. (width) x 90.55in. (height) with a maximum weight of 2000kg. This is to ensure the safety of our delivery partners.',
    },
    [vehicleTypeName.Truck330]: {
      dimensionText: '182.67 x 47.24 x 47.24 in',
      serviceLimitation:
        'The maximum acceptable size of all Lalamove items is 182.67in. (length) x 47.24in. (width) x 47.24in. (height) with a maximum weight of 2000kg. This is to ensure the safety of our delivery partners.',
    },
  },
};

export const CONVENIENCE_FEE = {
  REGULAR: 5,
  ADVANCE: 5,
};

export const PERCENT_BASE_FEE = 1.5;
