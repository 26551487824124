import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import PrivacyPolicyContents from '../../../../../shared/components/PrivacyPolicyContents';
import { PRIVACY_POLICY, SUB_PROCESSORS } from '../../../../../shared/util/enums/PrivacyPolicy';
import { handleReturnMyAccounts } from '../../../../../shared/utils/helpers/purefunctions';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../../shared/constants/Module';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigatePrevPage = () => {
    const link = location?.state?.link || '';
    if (link) {
      return navigate(link);
    }
    return handleReturnMyAccounts(navigate);
  };
  const { first_content, second_content } = PRIVACY_POLICY;

  let title = [];
  let data = [];
  SUB_PROCESSORS.map((item, index) => {
    const keys = Object.keys(SUB_PROCESSORS[index]);
    title.push(item[keys[0]]);
    item[keys[1]].map((item, idx) => {
      if (data[idx]) {
        data[idx].push(item);
      } else {
        data.push([]);
        data[idx].push(item);
      }
    });
  });

  const prevNavigate = {
    label: 'Privacy Policy',
    imgPath: '',
    path: MODULE_PATH.MY_ACCOUNTS.MAIN,
  };

  const Subprocessors = () => (
    <div className="sub-processors-container">
      <table>
        <tr>
          {title.map((item, idx) => {
            return <th>{item}</th>;
          })}
        </tr>
        {data.map(items => {
          return (
            <tr>
              {items.map((item, idx) => {
                if (idx === items.length - 1) {
                  return (
                    <td>
                      <a href={item} key={idx}>
                        {item}
                      </a>
                    </td>
                  );
                }
                return <td>{item}</td>;
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <StickyHeader title="Privacy Policy" onPrevNavigate={prevNavigate} />
        <div className="body">
          <div className="body-title">Privacy Policy</div>
          <div className="body-content">
            <PrivacyPolicyContents contents={first_content} />
            <Subprocessors />
            <PrivacyPolicyContents contents={second_content} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
