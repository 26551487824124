import NEW_ICON from '../../assets/images/AddressBook/bookmark_add.svg';
import BOOKMARK from '../../assets/images/AddressBook/bookmark.svg';
import CONTACT from '../../assets/images/AddressBook/contact.svg';
import CENTER_MAP from '../../assets/images/AddressBook/mapcenter.svg';
import PIN from '../../assets/images/AddressBook/pin.svg';
import TRASH from '../../assets/images/AddressBook/trash.svg';
// import PHONE_NO from '../../assets/images/AddressBook/phoneno.svg';
import PHONE_NO from '../../assets/svg/home-booking/icon_phonebook.svg';
import SEARCH_PIN from '../../assets/images/AddressBook/searchpin.svg';
import EMPTY_ADDRESS from '../../assets/images/AddressBook/empty-address.png';
import MAP from '../../assets/images/AddressBook/map.svg';
import CANCEL_CLEAR from '../../assets/images/AddressBook/cancelclear.png';
import EMPTY_ADDRESS_BOOK from '../../assets/images/AddressBook/empty_addressbook.svg';
import MARKER from '../../assets/images/AddressBook/marker.svg';
import CURRENT_LOC from '../../assets/images/AddressBook/current_location.svg';
import KM_ZERO from '../../assets/images/AddressBook/km_zero.png';
import ADDRESSBOOK_ICON from '../../assets/images/AddressBook/address-book.svg';

import L300 from '../../assets/images/Vehicle/L300.svg';
import PICKUP_TRUCK from '../../assets/images/Vehicle/L300.svg';
import MOTORCYCLE from '../../assets/images/Vehicle/motorcycle.svg';
import MPV from '../../assets/images/Vehicle/mpv.svg';
import SEDAN from '../../assets/images/Vehicle/sedan.svg';
import SUV from '../../assets/images/Vehicle/sedan.svg';
import TRUCK from '../../assets/images/Vehicle/truck.svg';

import ADD_ADDRESS_BTN from '../../assets/images/OndemandDelivery/add_address_button.png';
import EDIT from '../../assets/images/OndemandDelivery/edit.png';
import BOOKMARK_ADD from '../../assets/images/OndemandDelivery/bookmark_add.png';
import ON_DEMAND from '../../assets/images/OndemandDelivery/icon_menu_on-demand.png';
import GCASH from '../../assets/images/Icons/gcash.png';

import DOCUMENTS from '../../assets/images/OndemandDelivery/documents.svg';
import CLOTHING from '../../assets/images/OndemandDelivery/clothing.svg';
import FOOD from '../../assets/images/OndemandDelivery/food.svg';
import FRAGILE from '../../assets/images/OndemandDelivery/fragile.svg';
import ELECTRONICS from '../../assets/images/OndemandDelivery/electronics.svg';
import MEDICAL from '../../assets/images/OndemandDelivery/medical.svg';
import OTHERS from '../../assets/images/OndemandDelivery/others.svg';
import NO_ORDER_HOME from '../../assets/images/OndemandDelivery/no_orders_yet_animated.svg';
import NO_ORDER_ARROW_HOME from '../../assets/images/OndemandDelivery/arrow_new_order.svg';
import NO_ORDER_ALL from '../../assets/images/OndemandDelivery/no_orders_all.png';

import LIGHTNING from '../../assets/images/OndemandDelivery/lightning.svg';
import SELECTED from '../../assets/images/OndemandDelivery/selected.svg';
import CLOCK from '../../assets/images/OndemandDelivery/clock.svg';
import BOOK_ANY from '../../assets/images/OndemandDelivery/book_any_icon1.svg';
import HELP from '../../assets/images/OndemandDelivery/help.svg';
import HELP_GRAY from '../../assets/images/OndemandDelivery/help_gray.svg';

import FOOTER from '../../assets/images/Banners/footer.png';
import ORDER_DETAILS from '../../assets/images/Banners/overlay.png';
import COMING_SOON from '../../assets/images/Maintenance/comingsoonios.jpg';

import STANDARD from '../../assets/images/StandardDelivery/icon_menu_standard.png';
import PICK_UP from '../../assets/images/StandardDelivery/form_pickup_icon.png';
import DROP_OFF from '../../assets/images/StandardDelivery/form_dropoff_icon.png';
import SMALL from '../../assets/images/StandardDelivery/Box - Small-v2.svg';
import MEDIUM from '../../assets/images/StandardDelivery/Box - Medium-v2.svg';
import LARGE from '../../assets/images/StandardDelivery/Box - Large-v2.svg';
import EXTRA_LARGE from '../../assets/images/StandardDelivery/Box - Extra-Large-v2.svg';
import ICON_BASE_INFO from '../../assets/svg/icon_base_info.svg';

import VOUCHER_ICON from '../../assets/svg/icon_base_voucher.svg';
import CASHBACK_ICON from '../../assets/svg/icon_cashback.svg';
import CASHBACK_ICON_GRAY from '../../assets/svg/icon_base_gray_cashback.svg';
import PAYMENT_ICON_GRAY from '../../assets/svg/icon_base_gray_payment.svg';
import DECLARED_PRICE_ICON from '../../assets/svg/icon_base_declare_value.svg';
import GINSURE from '../../assets/svg/ginsure_logo.svg';
export const BG = {
  FOOTER,
  KM_ZERO,
  ORDER_DETAILS,
  COMING_SOON,
};

export const SCHEDULE = {
  LIGHTNING,
  SELECTED,
  CLOCK,
};

export const ITEM_TYPES = {
  FOOD,
  MEDICAL,
  FRAGILE,
  CLOTHING,
  DOCUMENTS,
  ELECTRONICS,
  OTHERS,
};

export const ICON = {
  MAP,
  PIN,
  EDIT,
  GCASH,
  TRASH,
  CONTACT,
  NEW_ICON,
  BOOKMARK,
  PHONE_NO,
  ON_DEMAND,
  STANDARD,
  PICK_UP,
  DROP_OFF,
  CENTER_MAP,
  CANCEL_CLEAR,
  BOOKMARK_ADD,
  EMPTY_ADDRESS,
  ADD_ADDRESS_BTN,
  SEARCH_PIN,
  EMPTY_ADDRESS_BOOK,
  MARKER,
  CURRENT_LOC,
  ADDRESSBOOK_ICON,
  NO_ORDER_HOME,
  NO_ORDER_ARROW_HOME,
  NO_ORDER_ALL,
  BOOK_ANY,
  HELP,
  HELP_GRAY,
  VOUCHER_ICON,
  CASHBACK_ICON,
  CASHBACK_ICON_GRAY,
  PAYMENT_ICON_GRAY,
  DECLARED_PRICE_ICON,
  ICON_BASE_INFO,
};

export const ON_DEMAND_ICON = {
  SUV,
  MPV,
  L300,
  SEDAN,
  TRUCK,
  PICKUP_TRUCK,
  MOTORCYCLE,
};

export const STANDARD_ICON = {
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
};

export const INSURANCE_ICONS = {
  GINSURE,
};
