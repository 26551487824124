import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import kgImage from '../../../../shared/assets/images/OndemandDelivery/kg.png';
import boxImage from '../../../../shared/assets/images/OndemandDelivery/box.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { STANDARD_ICON } from '../../../../shared/utils/enums/AppIcons';
import { COURIER_PRODUCT_SIZES } from '../../../../shared/utils/enums/StandardDelivery';

const ProductSize = ({ selectedProduct }) => {
  const navigate = useNavigate();

  const handleSelectProductSize = () => {
    navigate(MODULE_PATH.STANDARD.PRODUCT_LIST, {});
  };

  const ProductIcon = ({ value }) => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return <img src={STANDARD_ICON[productIcon]} alt={value} />;
  };

  return (
    <div className="product__type" onClick={() => handleSelectProductSize()}>
      <div className="title">Product Size</div>
      <div className="info">
        <ProductIcon value={selectedProduct.value} />
        <div className="product-info">
          <div className="header">{selectedProduct.value}</div>
          <div className="details">
            <>
              <img src={kgImage} alt="kg" /> {selectedProduct?.description?.weightText || ''}
            </>

            <>
              <img src={boxImage} className="product-size" alt="bag" />{' '}
              {selectedProduct?.description?.dimensionText || ''}
            </>
          </div>
        </div>
        <FontAwesomeIcon icon={solid('angle-right')} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedProduct: state.standardReducer.product,
});

export default connect(mapStateToProps, null)(ProductSize);
