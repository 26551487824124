export const PAGE = {
  HOME: 'Home',
  ADDRESS_BOOK: 'Address Book',
};

export const MODULE_PATH = {
  WELCOME: '/',
  HOME: '/home',
  ON_DEMAND: {
    MAIN: '/on-demand-delivery-form',
    VEHICLE: '/on-demand-vehicle-types-selection',
    COURIER: '/on-demand-couriers-selection',
  },
  STANDARD: {
    MAIN: '/standard-delivery-form',
    SENDER_DETAILS: '/standard-address-details-form',
    PRODUCT_LIST: '/product-list-selection',
    COURIER_LIST: '/standard-courier-list-selection',
    SELECT_ADDRESS: '/select-address',
    ORDER_HISTORY: '/order-history-page',
    VEHICLE: '/standard-vehicle-types-selection',
  },
  SHARED: {
    ON_DEMAND: {
      VEHICLE: '/on-demand-vehicle-types-selection',
      COURIER: '/on-demand-couriers-selection',
    },
    STANDARD: {
      VEHICLE: '/standard-vehicle-types-selection',
      COURIER: '/standard-couriers-selection',
    },
    VEHICLE: '/vehicle-types-selection',
    ITEM: '/item-details-selection',
    COURIER: '/couriers-selection',
  },
  ADDRESS: {
    ON_DEMAND: {
      // pick up and drop off
      SELECT_ADDRESS: '/on-demand-choose-address',
      SEARCH_LOCATION: '/on-demand-search-location',
      PICKUP_DETAILS: '/on-demand-address-details',
    },
    STANDARD: {
      // pick up and drop off
      SELECT_ADDRESS: '/standard-choose-address',
      SEARCH_LOCATION: '/standard-search-location',
      PICKUP_DETAILS: '/standard-pick-up-details',
    },
    SELECT_ADDRESS: '/choose-address',
    SEARCH_LOCATION: '/search-location',
    PICKUP_DETAILS: '/pick-up-details',
  },
  ADDRESS_BOOK: {
    MAIN: '/address-book',
    FORM: '/address-book-forms',
    MAP: '/address-book-map',
  },
  MY_ACCOUNTS: {
    MAIN: '/my-account',
    ACCOUNT_DETAILS: '/account-details',
    CONTACT_US: '/contact-us',
    ABOUT_US: '/about-us',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITIONS: '/terms-and-conditions',
    CASH_BACK: '/cash-back',
  },
  ORDERS: {
    VIEW_ALL_ORDERS: '/all-orders',
    PAYMENT_BREAKDOWN: '/payment-breakdown',
    ORDER_DETAILS: '/order-details',
    CANCEL_ORDER: '/order-cancellation',
    ORDER_CANCELLED: '/order-cancelled-page',
    SEARCH_ORDER: '/search-orders',
    FAILED_ORDER: '/failed-order',
  },
  PAYMENT: {
    ON_DEMAND: {
      MAIN: '/on-demand-payment-status-page',
      PAYMENT_SUMMARY: '/on-demand-payment-summary',
      PAYMENT_PROCESS: '/on-demand-processing-payment',
    },
    STANDARD: {
      MAIN: '/standard-payment-status-page',
      PAYMENT_SUMMARY: '/standard-payment-summary',
      PAYMENT_PROCESS: '/standard-processing-payment',
    },
    MAIN: '/payment-status-page',
    PAYMENT_SUMMARY: '/payment-summary',
    PAYMENT_PROCESS: '/processing-payment',
  },
  EXTRAS: {
    HELP: '/help-page',
    HELP_CENTER: '/help-center',
    LOADER: '/loading-page',
    ERROR: '/error-page',
    REFUND_ERROR: '/refund-error-page',
    COMING_SOON: '/coming-soon-page',
    COMING_SOON_IOS: '/coming-soon-ios',
    LANDING_PAGE: '/landing-page',
    MAINTENANCE: '/maintenance',
    UNAUTHORIZED: '/unauthorized',
    SEARCH_NEARBY_RIDERS: '/search-nearby-riders',
    TRACK_ORDER_MAP: '/track-order-map',
  },
};
