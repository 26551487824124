import { SET_CURRENT_USER, SET_CURRENT_LOCATION } from '../enums/types'

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user
})

export const setCurrentLocation = (location) => ({
  type: SET_CURRENT_LOCATION,
  payload: location
})