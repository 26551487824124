import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CANCEL_ORDER_REASONS, REFUND_STATUS } from '../../../../shared/utils/enums/AppConstants';
import { CANCEL_ERROR_MESSAGE } from '../../../../shared/utils/enums/Messages';
import DPHDao from '../../../../shared/utils/dao/DPH';
import PaymentDAO from '../../../../shared/utils/dao/PaymentDAO';
import OrdersDAO from '../../../../shared/utils/dao/Orders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { ICON } from '../../../../shared/utils/enums/AppIcons';

const CancelOrder = () => {
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);
  const [reason, setReason] = useState('');
  const [header, content] = CANCEL_ERROR_MESSAGE;

  const orderDetails = useSelector(state => state.homeReducer.targetOrder);
  const user = useSelector(state => state.usersReducer.currentUser);

  const { courierId, postId, status, refNo } = orderDetails;
  const meta = orderDetails?.meta ? JSON.parse(orderDetails?.meta) : {};
  const { trackingNumber } = meta;

  const handleClosed = () => {
    message.destroy();
  };

  const ErrorMessageContent = () => (
    <>
      <div className="message-header">
        {header} <FontAwesomeIcon icon={solid('x')} onClick={handleClosed} />
      </div>
      <div className="message-content">{content}</div>
    </>
  );

  let refundResultStatus;

  const handleCancelAndRefund = async () => {
    const ordersDao = new OrdersDAO();
    const paymentDao = new PaymentDAO();
    const { paymentAmount, paymentId, paymentRequestId } = orderDetails.gcash;
    const { gcash, isBroadcastOndemand } = orderDetails;
    const { Success } = REFUND_STATUS;

    let cancel, refund;
    navigate(MODULE_PATH.EXTRAS.LOADER);

    if (isBroadcastOndemand) {
      const fetchedOrders = await ordersDao.getPendingOrdersByOrderId(user.id, refNo);
      if (fetchedOrders.length !== 0) {
        let cancelMessage = [];

        fetchedOrders.map(async order => {
          const meta = order?.meta ? JSON.parse(order?.meta) : {};
          const { trackingNumber } = meta;

          ordersDao.updateOrder(order.postId, {
            gcash: {
              ...order.gcash,
              isCancelledByWeb: true,
              cancelledReason: reason,
              refund: {
                success: true,
              },
            },
          });

          try {
            cancel = await DPHDao.cancelPost({
              trackingNumber:
                order.status === 'QUEUED' && !order.gcash.pickupNow ? '' : trackingNumber,
              postId: order.postId,
              courierId: order.courierId,
            });
          } catch (err) {
            console.log(err.message);
          }

          if (
            !(cancel?.message || '').toLowerCase().includes('success') ||
            typeof cancel?.message === 'undefined'
          ) {
            cancelMessage.push(cancel?.message);
          }
        });

        if (cancelMessage.length > 0) {
          message.error({
            content: <ErrorMessageContent />,
            className: 'cancel-error',
            duration: 0,
            icon: null,
            closable: true,
            onClick: handleClosed,
          });
          navigate(MODULE_PATH.ORDERS.ORDER_DETAILS);
          return;
        }
      }

      refund = await paymentDao.refund({
        postId,
        paymentId,
        orderNo: refNo,
        paymentRequestId,
        refundAmount: paymentAmount || {},
        contactNumber: user.gcashNumber,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        refundReason: reason,
      });

      refundResultStatus = refund.result?.resultStatus;

      if (refundResultStatus === Success) {
        return navigate(MODULE_PATH.ORDERS.ORDER_CANCELLED);
      }

      return navigate(MODULE_PATH.EXTRAS.REFUND_ERROR, {
        state: { refundStatus: refundResultStatus, attempt: gcash?.refund?.attempt },
      });
    } else {
      ordersDao.updateOrder(postId, {
        gcash: {
          ...orderDetails.gcash,
          isCancelledByWeb: true,
          cancelledReason: reason,
          refund: {
            success: true,
          },
        },
      });

      let cancelError;

      try {
        cancel = await DPHDao.cancelPost({
          trackingNumber: status === 'QUEUED' && !gcash.pickupNow ? '' : trackingNumber,
          postId,
          courierId,
        });
      } catch (err) {
        cancelError = err.message;
      }

      if (
        !(cancel?.message || '').toLowerCase().includes('success') ||
        typeof cancel?.message === 'undefined'
      ) {
        message.error({
          content: <ErrorMessageContent />,
          className: 'cancel-error',
          duration: 0,
          icon: null,
          onClick: handleClosed,
        });
        navigate(MODULE_PATH.ORDERS.ORDER_DETAILS);
        return;
      }

      refund = await paymentDao.refund({
        postId,
        paymentId,
        orderNo: refNo,
        paymentRequestId,
        refundAmount: paymentAmount || {},
        contactNumber: user.gcashNumber,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        refundReason: reason,
      });

      refundResultStatus = refund.result?.resultStatus;

      if (refundResultStatus === Success) {
        return navigate(MODULE_PATH.ORDERS.ORDER_CANCELLED);
      }

      return navigate(MODULE_PATH.EXTRAS.REFUND_ERROR, {
        state: { refundStatus: refundResultStatus, attempt: gcash?.refund?.attempt },
      });
    }
  };

  const reasonsSelected = label => (reason === label ? 'selected' : '');

  const setSelectedReason = index => {
    setReason(CANCEL_ORDER_REASONS[index]);
    setIsSelected(true);
  };

  const SelectReason = ({ label, index }) => (
    <div
      className={`select-button ${reasonsSelected(label)}`}
      onClick={() => {
        setSelectedReason(index);
      }}
    >
      <div
        className={
          reason === label ? `circle-indicator-${reasonsSelected(label)}` : 'circle-indicator'
        }
      >
        <FontAwesomeIcon
          className={`fas fa-check-circle ${reasonsSelected(label)}`}
          icon={solid('circle-check')}
        />
      </div>
      <div className={`button-label ${reasonsSelected(label)}`}>{label}</div>
    </div>
  );

  const prevNavigate = {
    label: 'Cancel Order',
    imgPath: '',
    path: MODULE_PATH.ORDERS.ORDER_DETAILS,
  };

  return (
    <>
      <div className="cancel-order-container">
        <div className="cancel-order-content">
          <StickyHeader title="Cancel Order" onPrevNavigate={prevNavigate} />
          <div className="body">
            <div className="body-content">
              <div className="body-title"> Please select a reason. </div>
              {CANCEL_ORDER_REASONS.map((data, index) => (
                <SelectReason key={index} index={index} label={data} />
              ))}
            </div>
            <div
              className={isSelected ? 'proceed-button-selected' : 'proceed-button'}
              onClick={() => {
                handleCancelAndRefund();
              }}
            >
              <div className={isSelected ? 'button-label-selected' : 'button-label'}>Proceed</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CancelOrder;
