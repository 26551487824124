import moment from 'moment-timezone';
import { VEHICLE_TYPES } from '../utils/enums/DeliveryConstants';

export const ORDER_INITIAL_STATE = {
  pointLocation: '',
  courier: {},
  pickUpTime: 'PickUpNow',
  onDemandPartners: [],
  pickUp: {
    latitude: 0,
    longitude: 0,
    senderDetails: {
      fullName: '',
      contactNo: '',
      emailAddress: '',
      barangay: '',
      city: '',
      province: '',
      bldgStreet: '',
      unitFloorHouse: '',
    },
    notesToRider: '',
    pickupAddress: '',
    barangay: '',
    pickupCity: '',
    province: '',
  },
  cashbackPaymentDetails: {
    finalShippingFee: 0,
    cashbackDeduction: 0,
    paymentAmount: 0,
    isCashback: false,
  },
  dropOff: {
    latitude: 0,
    longitude: 0,
    recipientDetails: {
      fullName: '',
      contactNo: '',
      emailAddress: '',
      barangay: '',
      city: '',
      province: '',
      bldgStreet: '',
      unitFloorHouse: '',
    },
    notesToRider: '',
    deliveryAddress: '',
    barangay: '',
    deliveryCity: '',
    province: '',
  },
  // removed: pickupDateTime default value is null
  pickupDateTime: moment().valueOf(),
  defaultTimeTag: true,
  pickupDateTime: null,
  location: '',
  locationReference: false,
  itemType: '',
  itemDescription: '',
  itemPrice: '',
  itemApproxWeight: 1,
  // removed: vehicleType default value is {}
  vehicleType: { ...VEHICLE_TYPES[0], default: true },
  // vehicleType: {},
  defaultAddress: false,
  paymentRequestId: '',
  orderId: '',
  paymentReqs: {},
  paymentRes: {},
  driversTip: 0,
  voucherCode: '',
  cashBackAmount: 0,
  currentCourierRates: {},
  setPickedUp: false,
};

export const PICK_CARD = {
  NOW: 'PickUpNow',
  LATER: 'PickUpLater',
};
