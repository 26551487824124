import { useEffect, useState } from 'react';

const useRedirect = (delay = 5000) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedirect(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return redirect;
};

export default useRedirect;
