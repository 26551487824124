import React from 'react';

const OrderLoader = () => {
  return (
    <div className="order__container__loader" >
      <div className="row-1">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
      <div className="row-2">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
      <div className="row-3">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
    </div>
  );
};

export default OrderLoader;
