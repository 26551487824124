import produce from 'immer';
import { SET_TRANSACTION } from '../enums/types';

const initialState = {
  ongoingTarget: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TRANSACTION:
        draft.ongoingTarget = action.payload;
        break;
      default:
        return draft;
    }
  });
