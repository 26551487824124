import { get, isEmpty } from 'lodash';
import { GCASH, SERVICE_TYPE } from '../enums/AppConstants';
import { STANDARD_COURIERS } from '../enums/StandardDelivery';
import { COURIERS } from '../enums/DeliveryConstants';

export const scheduledPayload = (standardData, userInfo) => {
  const { pickUp, dropOff } = standardData;

  return {
    serviceType: SERVICE_TYPE.scheduled,
    pickup: {
      province: pickUp.senderDetails.province,
      city: pickUp.senderDetails.city,
      barangay: pickUp.senderDetails.barangay || '',
    },
    delivery: {
      province: dropOff.recipientDetails.province,
      city: dropOff.recipientDetails.city,
      barangay: dropOff.recipientDetails.barangay || '',
    },
  };
};

export const onDemandPayload = (onDemandData, couriers = [], userInfo = {}) => {
  const { pickUp, dropOff, vehicleType, pickupDateTime } = onDemandData;

  const weight = (vehicleType?.description.weightText.match(/\d+/g) || []).map(n => parseInt(n));
  const dimension = (vehicleType?.description.dimensionText.match(/\d+/g) || []).map(n =>
    parseInt(n)
  );

  return {
    couriers: isEmpty(couriers) ? COURIERS : couriers,
    pickup_province: !isEmpty(pickUp.senderDetails?.province)
      ? pickUp.senderDetails.province
      : 'METRO MANILA',
    data: {
      localeKey: 'en_PH',
      pickup: {
        name: pickUp.senderDetails.fullName,
        phone: String(pickUp.senderDetails.contactNo) || userInfo.currentUser.gcashNumber,
        address: pickUp.address,
        lat: pickUp.latitude,
        long: pickUp.longitude,
        email: pickUp.senderDetails.emailAddress || userInfo?.currentUser?.email,
      },
      delivery: {
        name: dropOff.recipientDetails.fullName,
        phone: String(dropOff.recipientDetails.contactNo),
        address: dropOff.address,
        lat: dropOff.latitude,
        long: dropOff.longitude,
      },
      codAmount: 0,
      scheduleAt: pickupDateTime,
      vehicleType: vehicleType.vehicleType.toUpperCase(),
      dimensions: {
        depth: dimension[2],
        width: dimension[1],
        height: dimension[2],
        weight: weight[0],
      },
      description: Object.values(vehicleType.description).join(', ') || 'Description',
    },
  };
};

export const partnersPayload = standardData => {
  const { pickUp, dropOff } = standardData;

  return {
    business_id: GCASH,
    applyAllCodes: true,
    voucher_codes: [],
    partners: STANDARD_COURIERS,
    pickup: {
      province: pickUp.senderDetails.province,
      city: pickUp.senderDetails.city,
      barangay: pickUp.senderDetails.barangay,
    },
    delivery: {
      province: dropOff.recipientDetails.province,
      city: dropOff.recipientDetails.city,
      barangay: dropOff.recipientDetails.barangay,
    },
  };
};
