import produce from 'immer'
import { SET_ADDRESS, SET_DEFAULT_ADDRESS, SET_SELECTED_ADDRESS, SET_LIST_ADDRESS, SET_ADDRESSBOOK_INITIAL_STATE, SET_RECENTLY_ADDRESS } from '../enums/types'

import { ADDRESSBOOK_INITIAL_STATE } from '../../constants/AddressBook'

const initialState = ADDRESSBOOK_INITIAL_STATE;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_DEFAULT_ADDRESS:
        draft.defaultAddress = action.payload;
        break;
      case SET_RECENTLY_ADDRESS:
        draft.recentlyAddress = action.payload;
        break;
      case SET_ADDRESS:
        draft.address = action.payload;
        break;
      case SET_SELECTED_ADDRESS:
        draft.selectedAddress = action.payload
        break;
      case SET_LIST_ADDRESS:
        draft.listOfAddress = action.payload
        break;
      case SET_ADDRESSBOOK_INITIAL_STATE:
        return initialState
      default:
        return draft;
    }
  })