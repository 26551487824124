import React, { useCallback, useEffect, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, List, Space, Tabs } from 'antd';
import { isEmpty } from '../utils/helpers/purefunctions';

const ProvinceCityBarangay = ({
  onShowDrawer,
  show,
  provinceOpt,
  onSetProvinceCityBrgy,
  province,
  city,
  barangay,
}) => {
  const [optionsList, setOptions] = useState({
    defaultKey: 'province',
    selectedProvince: '',
    selectedCity: '',
    selectedBrgy: '',
  });

  const [cityOpt, setCityOpt] = useState({});
  const [brgyOpt, setBrgyOpt] = useState([]);
  const [searchOrderInfo, setSearchOrderInfo] = useState(null);

  useEffect(() => {
    let __city = [];
    if (!isEmpty(province)) {
      const data = provinceOpt.data.filter(e =>
        e.name.toLowerCase().includes(province.toLowerCase())
      );

      if (data.length) {
        const childData = data[0].child;
        Object.keys(childData).map(name => {
          const child = childData[name].barangay_list;
          __city.push({
            name,
            child,
          });
        });
        setCityOpt({ types: 'city', data: __city });
      }
    }

    if (!isEmpty(__city)) {
      const brgy = __city.filter(e => e.name.toLowerCase().includes(city.toLowerCase()));
      if (brgy.length) {
        setBrgyOpt(brgy[0].child);
      }
    }

    setOptions({
      defaultKey: !isEmpty(province) ? optionsList.defaultKey : 'province',
      selectedProvince: province,
      selectedCity: city,
      selectedBrgy: barangay,
    });
  }, [province, city, barangay]);

  const onSelectList = (types, value, child) => {
    let obj = {
      defaultKey: types === 'province' ? 'city' : 'barangay',
    };
    if (types === 'province') {
      obj = {
        ...obj,
        selectedProvince: value || optionsList?.selectedProvince,
      };
    }

    if (types === 'city') {
      obj = {
        ...obj,
        selectedCity: value || optionsList?.selectedCity,
      };
    }

    if (types === 'barangay') {
      obj = {
        ...obj,
        selectedBrgy: value || optionsList?.selectedBrgy,
      };
    }

    setOptions(prevState => ({
      ...prevState,
      ...obj,
    }));

    setSearchOrderInfo(null);

    if (types === 'province') setCity(child);
    if (types === 'city') setBrgy(child);
    if (types === 'barangay') setBrgy(child);
  };

  const setCity = data => {
    const city = [];
    Object.keys(data).map(name => {
      const child = data[name].barangay_list;
      city.push({
        name,
        child,
      });
    });
    setCityOpt({ types: 'city', data: city });
  };

  const setBrgy = data => {
    setBrgyOpt(data);
  };

  const setSelectedBrgy = item => {
    setOptions(prevState => ({
      ...prevState,
      selectedBrgy: item,
    }));

    setSearchOrderInfo(null);

    const { selectedProvince, selectedCity } = optionsList;
    onSetProvinceCityBrgy({
      province: selectedProvince,
      city: selectedCity,
      barangay: item,
    });
    onShowDrawer();
  };

  const TabName = ({ label }) => {
    const placeholder = `Select ${label}`;
    let selected = '';

    if (label === 'Province') {
      selected = optionsList.selectedProvince;
    }

    if (label === 'City') {
      selected = optionsList.selectedCity;
    }

    if (label === 'Barangay') {
      selected = optionsList.selectedBrgy;
    }

    return (
      <span className={`tab-name-label ${selected === '' ? '' : 'hasValue'}`}>
        {String(selected || placeholder).toLowerCase()}
      </span>
    );
  };

  const DisplayProvCity = ({ list }) => {
    if (isEmpty(list)) return;
    const { types, data } = list;
    let sortedData = data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    if (types === 'province') {
      const index = sortedData.findIndex(e => e.name.toLowerCase() === 'metro manila');
      if (index > 0) {
        let el = sortedData[index];
        sortedData.splice(0, 0, el);
        sortedData.splice(index + 1, 1);
      }
    }

    if (
      (optionsList.defaultKey === 'province' ||
        optionsList.defaultKey === 'city' ||
        optionsList.defaultKey === 'barangay') &&
      searchOrderInfo
    ) {
      sortedData = filterByValueProvCity(sortedData, searchOrderInfo);
    }

    return (
      <List
        className="location-address"
        size="small"
        bordered
        dataSource={sortedData}
        renderItem={item => (
          <List.Item
            onClick={() => onSelectList(types, item.name, item.child)}
            className={`list-pcb-name ${
              item.name.toLowerCase() === optionsList.selectedProvince.toLowerCase() ||
              item.name.toLowerCase() === optionsList.selectedCity.toLowerCase()
                ? 'selected'
                : ''
            }`}
          >
            <span>{String(item.name).toLowerCase()}</span>
          </List.Item>
        )}
      />
    );
  };

  const DisplayBrgy = ({ list }) => {
    return (
      <List
        className="location-address"
        size="small"
        bordered
        dataSource={list}
        renderItem={item => (
          <List.Item
            onClick={() => setSelectedBrgy(item)}
            className={`list-pcb-name ${
              item.toLowerCase() === optionsList?.selectedBrgy?.toLowerCase() ? 'selected' : ''
            }`}
          >
            {String(item).toLowerCase()}
          </List.Item>
        )}
      />
    );
  };

  const filteredBrgy = brgyOpt.filter(data =>
    !isEmpty(searchOrderInfo) ? data.toLowerCase().includes(searchOrderInfo.toLowerCase()) : brgyOpt
  );

  const tabOpt = [
    {
      label: <TabName label="Province" />,
      children: <DisplayProvCity list={provinceOpt} />,
      key: 'province',
    },
    {
      label: <TabName label="City" />,
      children: <DisplayProvCity list={cityOpt} />,
      key: 'city',
      disabled: isEmpty(optionsList.selectedProvince) ? true : false,
    },
    {
      label: <TabName label="Barangay" />,
      children: <DisplayBrgy list={filteredBrgy} />,
      key: 'barangay',
      disabled: isEmpty(optionsList.selectedCity) ? true : false,
    },
  ];

  const onChange = key => {
    setOptions(prevState => ({
      ...prevState,
      defaultKey: key,
    }));
  };

  const filterByValueProvCity = (array, string) => {
    return array.filter(o =>
      Object.keys(o).some(
        k => typeof o[k] === 'string' && o[k].toLowerCase().includes(string.toLowerCase())
      )
    );
  };

  const onClose = () => {
    setOptions(prevState => ({
      ...prevState,
      defaultKey: 'province',
    }));
    onShowDrawer(false);
  };

  const handleChange = useCallback(e => {
    const { value } = e.target;
    setSearchOrderInfo(value);
  }, []);

  const handleClearSearch = () => {
    setSearchOrderInfo('');
  };

  return (
    <Drawer
      title="Select Address"
      placement="bottom"
      height="430px"
      className="select-address-drawer"
      open={show}
      onClose={onClose}
      closable={false}
      extra={
        <Space>
          <Button onClick={() => onShowDrawer(false)} className="close-btn">
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="search-location-pcb">
        <FontAwesomeIcon icon={solid('search')} />
        <input
          placeholder={`Search ${optionsList.defaultKey}`}
          value={searchOrderInfo}
          onChange={e => handleChange(e)}
          className="search-pcb"
        />
      </div>
      <Tabs
        hideAdd
        onClick={handleClearSearch}
        onChange={onChange}
        activeKey={optionsList.defaultKey}
        items={tabOpt}
        className="prov-city-brgy-options"
      />
    </Drawer>
  );
};

export default ProvinceCityBarangay;
