import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Tour } from 'antd';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import arrowRight from '../../../../shared/assets/images/OndemandDelivery/arrow_right.svg';
import IconOngoingDeliveries from '../../../../shared/assets/svg/home-booking/icon_ongoing-orders.svg';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { MODULE_PATH } from '../../../../shared/constants/Module';
// import { sortList } from '../../../../shared/utils/helpers/purefunctions';
import Tap from '../../../../shared/assets/svg/home-booking/Tap.svg';
import Login from '../../../../shared/utils/dao/Login';
import { setCurrentUser } from '../../../../shared/redux/users/actions';

const LegendOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.usersReducer.currentUser);
  const orderList = useSelector(state => state.homeReducer.orderList);
  const fetch = useSelector(state => state.homeReducer.fetch);
  const appAccess = useSelector(state => state.appAccessReducer);
  const [viewAll, onShowViewAll] = useState(false);

  const refs = {
    refOngoing: useRef(null),
    refViewAll: useRef(null),
  };

  const handleNavigateAllOrder = () => {
    if (user.recordCreated) {
      navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
    }
  };

  useEffect(() => {
    if (user?.recordCreated && !appAccess.legend) {
      const updateUserInfo = async () => {
        try {
          const currentUser = { ...user, tour: { ...(user?.tour || {} ), touredLegend: true} };
          dispatch(setCurrentUser(currentUser));

          const loginDao = new Login();
          await loginDao.updateUser(user.id, currentUser);

          onShowViewAll(undefined);
        } catch (error) {
          onShowViewAll(undefined);
          console.error('Error:', error);
        }
      };
      updateUserInfo();
    }
  }, [appAccess.legend]);

  const stepsOngoing = [
    {
      title: 'Tracking an Order?',
      description: 'Check out your ongoing orders and track them in real-time.',
      cover: (
        <div className="tour-delivery-type-description-bottom">
          <img src={Tap} alt="Tap to Continue" className="tap-to-continue-bottom" />
          <span>Top to Continue</span>
        </div>
      ),
      target: () => refs.refOngoing.current,
    },
  ];

  const stepsViewAll = [
    {
      title: 'Check All Orders',
      description: 'Check out details of your past or ongoing orders!',
      cover: (
        <div className="tour-delivery-type-description-bottom">
          <img src={Tap} alt="Tap to Continue" className="tap-to-continue-bottom" />
          <span>Top to Continue</span>
        </div>
      ),
      target: () => refs.refViewAll.current,
    },
  ];

  // const filterLatestOrder = sortList(orderList).slice(0, 9);
  return (
    <>
      <Tour
        open={user?.tour?.touredForm && appAccess.legend === true}
        steps={stepsOngoing}
        placement={'bottom'}
        className="tour-delivery-type"
        closeIcon={true}
      />
      <Tour
        open={user?.tour?.touredForm && appAccess.legend === 1}
        steps={stepsViewAll}
        placement={'bottom'}
        className="tour-delivery-type"
      />
      <div
        className={`legend-orders-container ${
          typeof appAccess?.legend === 'undefined' ? 'shrink' : ''
        }`}
      >
        <div className="legend-orders" ref={refs.refOngoing}>
          <img src={IconOngoingDeliveries} alt="Ongoing Deliveries" />
          <span className="legend-deliveries">
            <span>{orderList.length}</span> Ongoing Deliveries
          </span>
        </div>
        <div className="legend-orders" ref={refs.refViewAll}>
          {fetch && (
            <span onClick={handleNavigateAllOrder}>
              <span className="legend-view">View here</span>
              <span className="arrow-right">
                <img src={arrowRight} alt="arrow-right" />
              </span>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default LegendOrders;
