import React, { useEffect, useState } from 'react';

import GCashLogo from '../../shared/assets/svg/landing-page/gcash-logo.svg';
import ParcelsLogo from '../../shared/assets/svg/landing-page/parcels-logo.svg';
import ParcelsText from '../../shared/assets/svg/landing-page/parcels-text.svg';
import FirstLogo from '../../shared/assets/svg/landing-page/logo-1.svg';

const DottedLoader = () => {
  const [showFirstComponent, setShowFirstComponent] = useState(true);
  const [showSecondComponent, setShowSecondComponent] = useState(false);
  const [isContent, setIsContent] = useState(false);

  useEffect(() => {
    const firstTimer = setTimeout(() => {
      setShowFirstComponent(false);
      setShowSecondComponent(true);
    }, 500);

    const secondTimer = setTimeout(() => {
      setShowSecondComponent(false);
      setIsContent(true);
    }, 1000);

    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
    };
  }, []);

  const isFirstComponent = showFirstComponent ? 'first-component' : '';
  const isSecondComponent = showSecondComponent ? 'second-component' : '';

  const Dots = () => (
    <div className="dot-container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );

  return (
    <div className={`loader  ${isFirstComponent} ${isSecondComponent}`}>
      {showFirstComponent && <img className="first-logo" src={FirstLogo} alt="first logo" />}
      {showSecondComponent && <img className="second-logo" src={ParcelsLogo} alt="second logo" />}
      {isContent && (
        <>
          <div className="description">
            We are redirecting you to our trusted partner, Parcels PH.
          </div>
          <div className="animation">
            <img src={GCashLogo} alt="GCash logo" />
            <Dots />
            <img src={ParcelsLogo} alt="ParcelsPH logo" />
            <img src={ParcelsText} alt="Parcels Text" />
          </div>
        </>
      )}
    </div>
  );
};

export default DottedLoader;
