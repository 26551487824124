import React from 'react';

const AssignRiderLoader = ({ SearchingNearbyRiderClass }) => {
  return (
    <div className={`assigning__rider__loader__container ${SearchingNearbyRiderClass}`}>
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
    </div>
  );
};

export default AssignRiderLoader;
