import React, { useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActiveMyOrdersSVG from '../../../../shared/assets/images/Icons/Menu Icons/my-orders-active.svg';
import InactiveMyOrdersSVG from '../../../../shared/assets/images/Icons/Menu Icons/my-orders-inactive.svg';
import InactiveAddressBookSVG from '../../../../shared/assets/images/Icons/Menu Icons/address-book-inactive.svg';
import ActiveAddressBookSVG from '../../../../shared/assets/images/Icons/Menu Icons/address-book-inactive-1.svg';
import AddNewOrderSVG from '../../../../shared/assets/images/Icons/Menu Icons/add-new-order-icon-1.svg';
import OnDemandSVG from '../../../../shared/assets/svg/OnDemandDelivery.svg';
import StandardSVG from '../../../../shared/assets/svg/StandardDelivery.svg';
import { Button, Drawer, Space, Tour } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { setFormView } from '../../../../shared/redux/home/actions';
import { setStandardInitialState } from '../../../../shared/redux/standard/actions';
import { MODULE_PATH, PAGE } from '../../../../shared/constants/Module';
import { BG } from '../../../../shared/utils/enums/AppIcons';
import { setShowTooltip } from '../../../../shared/redux/home/actions';

import DPHDao from '../../../../shared/utils/dao/DPH';
import { useQuery } from '@tanstack/react-query';
import { setOnDemandPartners } from '../../../../shared/redux/onDemand/actions';

const FooterOld = ({ view, setFormView, tooltip, user }) => {
  const [drawer, setDrawer] = useState(false);

  const ref1 = useRef(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleShowDrawer = e => {
    e.stopPropagation();
    dispatch(setShowTooltip(false));
    setDrawer(true);
  };
  const onClose = e => {
    e.stopPropagation();
    if (tooltip) return;
    setDrawer(false);
  };

  const { data } = useQuery({
    queryKey: ['getPartners'],
    queryFn: async () => {
      return await DPHDao.getPartners();
    },
  });

  dispatch(setOnDemandPartners(data));

  const navigateToOnDemand = () => {
    dispatch({ type: 'SET_TARGET_ORDER', payload: {} });
    setFormView(MODULE_PATH.ON_DEMAND.MAIN);
    navigate(MODULE_PATH.ON_DEMAND.MAIN);
  };

  const navigateToStandard = () => {
    dispatch({ type: 'SET_TARGET_ORDER', payload: {} });
    setFormView(MODULE_PATH.STANDARD.MAIN);
    navigate(MODULE_PATH.STANDARD.MAIN);
  };

  const navigateToHome = () => {
    setFormView(PAGE.HOME);
    navigate(MODULE_PATH.HOME);
  };
  const navigateToAddressBook = e => {
    e.stopPropagation();
    if (user.recordCreated) {
      setFormView(PAGE.ADDRESS_BOOK);
      navigate(MODULE_PATH.ADDRESS_BOOK.MAIN);
    } else {
      !tooltip && dispatch(setShowTooltip(true));
    }
  };

  const styles = {
    cover: {
      backgroundImage: `url(${BG.FOOTER})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  };

  const steps = [
    {
      title: 'Ready to book?',
      description: 'Enter delivery details, pick a courier, and more!',
      cover: (
        <div className='home-tour-new-order'>
          <img
            src={view === PAGE.ADDRESS_BOOK ? ActiveAddressBookSVG : InactiveAddressBookSVG}
            alt="Address Book"
            className="address-book-icon"
          />
          <span>Top to Continue</span>
        </div>
      ),
      target: () => ref1.current,
    },
  ];

  const handleReadyToBook = e => {
    dispatch(setShowTooltip(false));
  };

  const handleDrawer = e => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="container__footer" style={styles.cover}>
        <div className="bar">
          <div className="footer-button">
            <div className="my-orders-container">
              <button
                className={`my-orders-btn ${view === PAGE.HOME ? '' : 'inactive'}`}
                onClick={navigateToHome}
              >
                <img
                  src={view === PAGE.HOME ? ActiveMyOrdersSVG : InactiveMyOrdersSVG}
                  alt="My Orders"
                  className="my-orders-icon"
                />
                Orders
              </button>
            </div>
            <div className="add-new-order">Add New Order</div>
            <div className="address-book-container">
              <button
                className={`address-book-btn ${view === PAGE.ADDRESS_BOOK ? '' : 'inactive'}`}
                onClick={e => navigateToAddressBook(e)}
              >
                <img
                  src={view === PAGE.ADDRESS_BOOK ? ActiveAddressBookSVG : InactiveAddressBookSVG}
                  alt="Address Book"
                  className="address-book-icon"
                />
                Address Book
              </button>
            </div>
          </div>
          <button ref={ref1} className="button" onClick={e => handleShowDrawer(e)}>
            <img src={AddNewOrderSVG} alt="Add New Order" className="add-new-order-icon" />
          </button>
        </div>
      </div>

      {/* <Tour
        maskClosable={!tooltip}
        open={tooltip}
        onClose={handleReadyToBook}
        steps={steps}
        placement={'top'}
        className='tour-home-new'
      /> */}
      <Drawer
        title="Service Type"
        placement="bottom"
        height="330px"
        className="service-type-drawer"
        onClose={e => onClose(e)}
        open={drawer}
        closable={false}
        extra={
          <Space>
            <Button onClick={e => onClose(e)} className="close-btn">
              <FontAwesomeIcon icon={solid('x')} />
            </Button>
          </Space>
        }
        onClick={e => handleDrawer(e)}
      >
        <p className="service-tip">Charges will only apply upon placing order.</p>
        <div className="on-demand-option" onClick={navigateToOnDemand}>
          <div className="on-demand-icon">
            <img src={OnDemandSVG} alt="On-Demand Delivery" />
          </div>
          <div className="on-demand-content">
            <h3>On-Demand Delivery</h3>
            <p>Deliver your items instantly.</p>
          </div>
        </div>
        <div className="standard-option" onClick={navigateToStandard}>
          <div className="standard-icon">
            <img src={StandardSVG} alt="Standard Delivery" />
          </div>
          <div className="standard-content">
            <h3>Standard Delivery</h3>
            <p>Deliver your items within 2 or more days.</p>
          </div>
        </div>
      </Drawer>
    </>
  );
};
const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
      setStandardInitialState,
    },
    dispatch
  );
};
const mapStateToProps = state => ({
  view: state.homeReducer.view,
  tooltip: state.homeReducer.tooltip,
  orderList: state.homeReducer.orderList,
  fetched: state.homeReducer.fetch,
  user: state.usersReducer.currentUser,
});
export default connect(mapStateToProps, matchDispatchToProps)(FooterOld);
