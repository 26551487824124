import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const OrderControlBtn = ({
  onClickForRefund,
  onClickTrackOrder,
  onClickBookAgain,
  paymentStatus,
  isStandardDelivery,
  refundAttempt,
  status,
  isBookAny,
}) => {
  const initialStatuses = ['ASSIGNED', 'ACCEPTED', 'ASSIGNING_RIDER', 'CANCELLED'];

  if (status === 'DONE') {
    return (
      <div className="track-order-btn" onClick={onClickBookAgain}>
        <span>Book Again</span>
      </div>
    );
  }

  if (status === 'CANCELLED') {
    if (!paymentStatus) {
      if (isStandardDelivery) {
        return null;
      }
      if (refundAttempt >= 3) {
        return (
          <div className="track-order-btn disable">
            <span>For Refund</span>
          </div>
        );
      }
      return (
        <div className="track-order-btn" onClick={onClickForRefund}>
          <span>For Refund</span>
        </div>
      );
    }
    return (
      <div className="track-order-btn" onClick={onClickBookAgain}>
        <span>Rebook</span>
      </div>
    );
  }
  if (isBookAny && initialStatuses.includes(status)) {
    return (
      <div className="track-order-btn-disabled">
        <FontAwesomeIcon icon={solid('location-arrow')} />
        <span>Track Order</span>
      </div>
    );
  }

  return (
    <div className="track-order-btn" onClick={onClickTrackOrder}>
      <FontAwesomeIcon icon={solid('location-arrow')} />
      <span>Track Order</span>
    </div>
  );
};

export default OrderControlBtn;
