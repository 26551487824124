import React from 'react';
import { useNavigate } from 'react-router-dom';
import FindCourier from '../../../../shared/assets/images/OndemandDelivery/find_courier.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import boxImage from '../../../../shared/assets/images/OndemandDelivery/box.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { formatBills, isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import { message } from 'antd';

const Courier = () => {
  const navigate = useNavigate();
  const standard = useSelector(state => state.standardReducer);
  const { pickUp, dropOff } = standard;

  const handleSelectCourier = () => {
    if (isEmpty(pickUp?.senderDetails?.address) || isEmpty(dropOff?.recipientDetails?.address)) {
      return message.error('Pickup and Drop-off address are required.', 3.0);
    }
    navigate(MODULE_PATH.STANDARD.COURIER_LIST, {});
  };

  const courier = useSelector(state => state.standardReducer.courier);
  const selectedProduct = useSelector(state => state.standardReducer.product);

  return (
    <div className="courier" onClick={() => handleSelectCourier()}>
      <div className="title">Couriers</div>
      <div className="info">
        <img src={FindCourier} alt="Find courier" />
        <div className="courier-info">
          <div className="header">
            {!isEmpty(courier) ? courier?.courier : 'Find a courier'}{' '}
            {isEmpty(courier) && <span>*</span>}
          </div>
          <div className="details">
            {isEmpty(courier) ? (
              'Courier Description'
            ) : (
              <>
                <img src={boxImage} alt="bag" />
                <span>{selectedProduct.description.dimensionText}</span>
              </>
            )}
          </div>
        </div>
        <div className="price">{!isEmpty(courier) ? `P ${formatBills(courier.rate)}` : ''}</div>
        <FontAwesomeIcon icon={solid('angle-right')} />
      </div>
    </div>
  );
};

export default Courier;
