import React, { useRef, useState } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';
import SilverMapStyle from '../utils/enums/SilverMapStyle.json';
import { ICON } from '../utils/enums/AppIcons';

const StaticMap = ({ coordinates }) => {
  const position = { lat: Number(coordinates.lat), lng: Number(coordinates.lng) };

  const staticMapRef = useRef(null);

  return (
    <GoogleMap
      ref={staticMapRef}
      defaultZoom={13}
      defaultCenter={position}
      center={position}
      defaultOptions={{
        styles: SilverMapStyle,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        gestureHandling: 'none',
        disableDefaultUI: true,
      }}
    >
      <Marker
        position={position}
        icon={{
          url: ICON.MARKER,
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      />
    </GoogleMap>
  );
};
export default withScriptjs(withGoogleMap(StaticMap));
