import React from 'react';
import { Drawer, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from '../../../../shared/components/SubmitButton';

const CashbackHelp = ({ show, onSetShow }) => {
  return (
    <Drawer
      title="Cashback"
      placement="bottom"
      height="r50px"
      className="book__any__drawer"
      onClose={() => onSetShow()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => onSetShow()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="cashback__drawer__container">
        <div className="cashback__instruction">
          <span className="cashback__info">
            Getting cashback involves receiving a portion of the purchase amount back as a refund or
            reward. Here are several ways to get cashback:
          </span>
          <ol>
            <li>
              Title
              <ul>
                <li>Descriptions</li>
              </ul>
            </li>
            <li>
              Title
              <ul>
                <li>Descriptions</li>
              </ul>
            </li>
          </ol>
        </div>

        <SubmitButton isProceedValue={true} name="Got it" onClick={() => onSetShow()} />
      </div>
    </Drawer>
  );
};

export default CashbackHelp;
