import axios from 'axios';
import { DEPLOYMENT } from '../enums/AppConstants';
import { DPH_API_ERROR_MESSAGE } from '../enums/Messages';
import { message } from 'antd';

let sessionToken, apiKey;
let API = DEPLOYMENT.DPH_API;
let SERVICE_MATRIX = DEPLOYMENT.DPH_SERVICE_MATRIX;
let SERVICE_MATRIX_AUTH = DEPLOYMENT.DPH_SERVICE_MATRIX_AUTH;
let EMAIL = DEPLOYMENT.EMAIL;
let PASSWORD = DEPLOYMENT.PASSWORD;
let isLoggedIn = false;

class DPH {
  static async login() {
    if (!isLoggedIn) {
      const response = await axios({
        method: 'POST',
        url: `${API}/login`,
        headers: {
          Accept: 'application/json',
        },
        data: {
          email: EMAIL,
          password: PASSWORD,
        },
      });
      const { results } = response.data;
      sessionToken = results.sessionToken;
      apiKey = results.apiKey;
      isLoggedIn = true; // Update flag to indicate login
    } else {
      // console.log('Already logged in, not logging in again.');
    }
  }

  static async getOnDemandQuotation(data) {
    try {
      const getQuotation = await axios({
        method: 'POST',
        url: `${API}/v3/getOnDemandQuotation`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        data,
        params: {
          apiKey,
        },
      });
      return getQuotation.data.result;
    } catch (err) {
      return this.handle401Error(err, 'getOnDemandQuotation', [data]);
    }
  }

  static async getAvailableScheduledPartner(data) {
    try {
      const getQuotation = await axios({
        method: 'POST',
        url: `${API}/v3/getAvailablePartners`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        data,
        params: {
          apiKey,
        },
      });
      const couriers = ['QUADX', 'NINJAVAN', 'XDE', 'CLIQNSHIP'];
      const courierList = getQuotation.data.result;
      const filterCouriers = courierList.filter(courier => couriers.includes(courier.id));
      return { filterCouriers, success: getQuotation.data.success };
    } catch (err) {
      return this.handle401Error(err, 'getAvailableScheduledPartner', [data]);
    }
  }

  static async getByPartners(data) {
    try {
      const result = await axios({
        method: 'POST',
        url: `${SERVICE_MATRIX}/serviceMatrix/getByPartners`,
        headers: {
          Accept: 'application/json',
          Authorization: SERVICE_MATRIX_AUTH,
        },
        data,
        params: {
          apiKey,
        },
      });
      return result.data;
    } catch (err) {
      return this.handle401Error(err, 'getByPartners', [data]);
    }
  }

  static async createOrder(data, partnerId) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${DEPLOYMENT.PARCELS_API}/v1/booking/createOrder`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${DEPLOYMENT.PARCELS_AUTH}`,
          'auth-token': sessionToken,
        },
        data,
        params: {
          partnerId,
          apiKey,
        },
      });
      // const formattedData = JSON.stringify(response.data, null, 2);
      // const maxLength = 200;
      // const displayData =
      //   formattedData.length > maxLength
      //     ? `${formattedData.substring(0, maxLength)}`
      //     : formattedData;
      // message.info(`create order api 1:\n${displayData}`);
      return response.data;
    } catch (err) {
      /* calling handle401Error to call createOrder method with login */
      return this.handle401Error(err, 'createOrder', [data, partnerId]);
    }
  }

  static async fetchPosts() {
    try {
      const response = await axios({
        method: 'GET',
        url: `${API}/getPost`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
          partnerId: 'GCASH',
          type: 'post',
        },
      });
      return response.data.result;
    } catch (err) {
      return this.handle401Error(err, 'fetchPosts', []);
    }
  }

  static async getPartners() {
    if (!isLoggedIn) {
      await this.login();
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${API}/getPartners`,
        headers: {
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
        },
      });
      isLoggedIn = true;
      return response.data.results;
    } catch (err) {
      return this.handle401Error(err, 'getPartners', []);
    }
  }

  static async cancelPost(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API}/v3/cancelPost`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
        },
        data,
      });
      return response.data;
    } catch (err) {
      return this.handle401Error(err, 'cancelPost', [data]);
    }
  }

  static async handle401Error(err, method, args) {
    /*
      err - err params in catch from try catch function/method
      method - method name or function name
      args - request payload/params
    */
    const { response } = err || {};
    const status = response?.status;
    const data = response?.data;

    const isApiResponseError = status === 401 && DPH_API_ERROR_MESSAGE.includes(data.message);

    if (isApiResponseError) {
      isLoggedIn = false;
      await this.login();
      return this[method](...args);
    } else {
      throw err;
    }
  }
}

export default DPH;
