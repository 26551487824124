import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { copyToClipBoard } from '../../utils/helpers/purefunctions';
import { useLocation, useNavigate } from 'react-router';
import { MODULE_PATH } from '../../constants/Module';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingOrders } from '../../redux/home/actions';

function ProviderMaps() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [trackingUrlState, setTrackingUrlState] = useState('');

  const orders = useSelector(state => state.homeReducer.orderList);
  const user = useSelector(state => state.usersReducer.currentUser);

  const order = orders[0];
  const deliveryDetails = order?.meta ? JSON.parse(order?.meta) : {};
  const trackingUrlFromOrderPlaced = location?.state?.trackingUrl;
  const url = trackingUrlFromOrderPlaced === 'N/A' ? trackingUrlState : trackingUrlFromOrderPlaced;

  const fetchOrders = useCallback(() => {
    dispatch(fetchPendingOrders(user.id));
  }, [dispatch, user.id]);

  useEffect(() => {
    fetchOrders();
  }, [fetchPendingOrders, user.id]);

  useEffect(() => {
    setTrackingUrlState(deliveryDetails?.trackingUrl);
  }, [deliveryDetails?.trackingUrl]);

  return (
    <div className="embedded__map">
      <div className="header">
        <Button
          className="back-button"
          icon={<FontAwesomeIcon icon={solid('arrow-left')} />}
          onClick={() => navigate(MODULE_PATH.HOME)}
        />
        <FontAwesomeIcon
          icon={solid('arrow-up-right-from-square')}
          onClick={() => copyToClipBoard(url)}
        />
      </div>
      <iframe src={url} title="Track Order" width="100%" height="600" loading="lazy" />
    </div>
  );
}

export default ProviderMaps;
