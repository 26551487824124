import axios from "axios";
import moment from "moment-timezone";
import { API_BASE_URL } from "./AppConstants";

export function getServerTime(fnCallback) {
  return axios
    .get(`${API_BASE_URL}/getServerTime`)
    .then((result) => {
      fnCallback(moment(result.data.results).valueOf());
    })
    .catch(() => {
      fnCallback(moment().valueOf());
    });
}
