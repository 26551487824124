import React from 'react';

const FooterBtn = ({ label, onClick, ...rest }) => {
  return (
    <div className={`footer__btn ${label} ${rest?.className}`} onClick={onClick}>
      <div className={`btn ${rest?.childClass}`}>{label}</div>
    </div>
  );
};

export default FooterBtn;
