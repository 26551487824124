import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatText, isEmpty, formatThousandAmount } from '../utils/helpers/purefunctions';
import ItemDetailsList from './ItemDetailsList';
import BoxaddItem from '../assets/images/OndemandDelivery/box_add_item.png';
import { MODULE_PATH } from '../constants/Module';
import { ICON, ITEM_TYPES } from '../utils/enums/AppIcons';

const ItemDetails = ({
  view,
  onDemandItemType,
  standardItemType,
  onDemandItemDescription,
  onDemandItemPrice,
  standardItemPrice,
  standardItemDescription,
}) => {
  const [showItemDetailsDrawer, setItemDetailsDrawer] = useState(false);

  const isStandard = view === MODULE_PATH.STANDARD.MAIN;
  const itemType = isStandard ? standardItemType : onDemandItemType;
  const itemPrice = isStandard ? standardItemPrice : onDemandItemPrice;
  const itemDescription = isStandard ? standardItemDescription : onDemandItemDescription;

  return (
    <>
      <div className="item__details" onClick={() => setItemDetailsDrawer(true)}>
        <div className="title">Item Details</div>
        <div className="info">
          <img src={BoxaddItem} alt="motorcycle" />
          <div className="item-info">
            <div className="header">
              {itemType ? `${formatText(itemType)}` : `Add Item Details`}
              {!itemType && <span>*</span>}
            </div>
            <div className="details">
              {itemDescription ? (
                <>
                  <img
                    className="details-description"
                    src={ITEM_TYPES[itemType.toUpperCase()]}
                    alt="item"
                  />
                  <span className="item-description">{itemDescription}</span>
                </>
              ) : !isEmpty(Number(itemPrice)) ? (
                ''
              ) : (
                `Item Description`
              )}
              {!isEmpty(Number(itemPrice)) ? (
                <>
                  <img
                    className={`${itemDescription ? 'details-price' : 'details-price-only'}`}
                    src={ICON.DECLARED_PRICE_ICON}
                    alt="item"
                  />
                  {`Php ${formatThousandAmount(itemPrice)}`}
                </>
              ) : null}
            </div>
          </div>
          <FontAwesomeIcon icon={solid('angle-right')} />
        </div>
      </div>
      <ItemDetailsList
        show={showItemDetailsDrawer}
        onCloseItemDetailsDrawer={() => setItemDetailsDrawer(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  view: state.homeReducer.view,
  onDemandItemType: state.onDemandReducer.itemType,
  standardItemType: state.standardReducer.itemType,
  onDemandItemDescription: state.onDemandReducer.itemDescription,
  onDemandItemPrice: state.onDemandReducer.itemPrice,
  standardItemPrice: state.standardReducer.itemPrice,
  standardItemDescription: state.standardReducer.itemDescription,
});

export default connect(mapStateToProps, null)(ItemDetails);
