import React from 'react';
import Loading from '../../../../shared/assets/images/Refund/loader.gif';

const Loader = () => {
  return (
    <>
      <div className="loader-container">
        <img src={Loading} alt="loader" className="laoder" />
        <div className="loader-message">
          <div>Refund is in process.</div>
          <div>Please do not close the app.</div>
        </div>
      </div>
    </>
  );
};
export default Loader;
