import produce from 'immer';
import {
  SET_ONDEMAND_ORDER_ID,
  SET_ONDEMAND_POINT_LOCATION,
  SET_ONDEMAND_COURIER,
  SET_ONDEMAND_PICK_UP,
  SET_ONDEMAND_DROP_OFF,
  SET_ONDEMAND_PICKUP_DATETIME,
  SET_VEHICLE_TYPE,
  SET_ONDEMAND_ITEM_TYPE,
  SET_ONDEMAND_ITEM_DESCRIPTION,
  SET_ONDEMAND_ITEM_PRICE,
  SET_ONDEMAND_ITEM_APPROX_WEIGHT,
  SET_ONDEMAND_PICKUP_TIME,
  SET_ONDEMAND_INITIAL_STATE,
  SET_PAYMENT_REQUEST_ID,
  SET_LOCATION,
  SET_LOCATION_REFERENCE,
  SET_DRIVERS_TIP,
  SET_VOUCHER_CODE,
  SET_ONDEMAND_CASHBACK_AMOUNT,
  SET_CURRENT_COURIER_RATES,
  SET_ONDEMAND_PARTNERS,
  SET_CASHBACK_PAYMENT_DETAILS,
  SET_PICKED_UP,
  SET_DEFAULT_TIME,
} from '../enums/types';

import { ORDER_INITIAL_STATE } from '../../constants/OnDemand';

const initialState = ORDER_INITIAL_STATE;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_ONDEMAND_ORDER_ID:
        draft.orderId = action.payload;
        break;
      case SET_ONDEMAND_POINT_LOCATION:
        draft.pointLocation = action.payload;
        break;
      case SET_ONDEMAND_COURIER:
        draft.courier = action.payload;
        break;
      case SET_ONDEMAND_PICK_UP:
        draft.pickUp = action.payload;
        break;
      case SET_ONDEMAND_DROP_OFF:
        draft.dropOff = action.payload;
        break;
      case SET_ONDEMAND_PICKUP_DATETIME:
        draft.pickupDateTime = action.payload;
        break;
      case SET_VEHICLE_TYPE:
        draft.vehicleType = action.payload;
        break;
      case SET_ONDEMAND_ITEM_TYPE:
        draft.itemType = action.payload;
        break;
      case SET_ONDEMAND_ITEM_DESCRIPTION:
        draft.itemDescription = action.payload;
        break;
      case SET_ONDEMAND_ITEM_PRICE:
        draft.itemPrice = action.payload;
        break;
      case SET_ONDEMAND_ITEM_APPROX_WEIGHT:
        draft.itemApproxWeight = action.payload;
        break;
      case SET_ONDEMAND_PICKUP_TIME:
        draft.pickUpTime = action.payload;
        break;
      case SET_PAYMENT_REQUEST_ID:
        draft.paymentRequestId = action.payload;
        break;
      case SET_LOCATION:
        draft.location = action.payload;
        break;
      case SET_LOCATION_REFERENCE:
        draft.locationReference = action.payload;
        break;
      case SET_DRIVERS_TIP:
        draft.driversTip = action.payload;
        break;
      case SET_ONDEMAND_CASHBACK_AMOUNT:
        draft.cashBackAmount = action.payload;
        break;
      case SET_CURRENT_COURIER_RATES:
        draft.currentCourierRates = action.payload;
        break;
      case SET_VOUCHER_CODE:
        draft.voucherCode = action.payload;
        break;
      case SET_ONDEMAND_PARTNERS:
        draft.onDemandPartners = action.payload;
        break;
      case SET_CASHBACK_PAYMENT_DETAILS:
        draft.cashbackPaymentDetails = action.payload;
        break;
      case SET_PICKED_UP:
        draft.setPickedUp = action.payload;
        break;
      case SET_DEFAULT_TIME:
        draft.defaultTimeTag = action.payload;
        break;
      case SET_ONDEMAND_INITIAL_STATE:
        return initialState;
      default:
        return draft;
    }
  });
