/* global my */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './shared/assets/less/app.less';
import './shared/styles/tailwind.css';
import 'antd/dist/reset.css';
import { Provider } from 'react-redux';
import 'firebase/auth';
import { auth } from './shared/utils/helpers/firebaseUtils';
import store from './shared/redux/store';
import { setAppAccess } from './shared/redux/app/actions';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { setSSOCookies } from './shared/utils/helpers/SSO';
import App from './app/body/index';
import { queryClient } from './shared/utils/helpers/QueryClient';
import { QueryClientProvider } from '@tanstack/react-query';

import ReactGA from 'react-ga4';
import {
  DEPLOYMENT as GCASH_CREDS,
  APP_ACCESS,
  MERCHANT_ID,
} from './shared/utils/enums/AppConstants';
import { APP_ZONE } from './shared/config/app';

ReactGA.initialize(MERCHANT_ID.PRE_PRODUCTION);
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = (AppComponent, store) => {
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppComponent />
      </QueryClientProvider>
    </Provider>
  );
};

const autoSignIn = async () => {
  try {
    const { EMAIL: email, PASSWORD: password } = GCASH_CREDS;
    const res = await signInWithEmailAndPassword(auth, email, password);

    setSSOCookies([
      {
        name: 'uId',
        value: res.user.uid,
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};
const runApp = async () => {
  try {
    await autoSignIn();
    render(App, store);
  } catch (err) {
    store.dispatch(setAppAccess(APP_ACCESS.UNAUTHORIZED));
    render(App, store);
    auth.signOut();
  }
};

runApp();
