import React, { createContext, useState } from 'react';

const ItemDecriptionContext = createContext();

export const InputItemDescription = ({ children }) => {
  const [description, setDescription] = useState('');

  return (
    <ItemDecriptionContext.Provider value={{ description, setDescription }}>
      {children}
    </ItemDecriptionContext.Provider>
  );
};

export default ItemDecriptionContext;
