import produce from 'immer';
import { STANDARD_INITIAL_STATE } from '../../constants/Standard';
import {
  SET_PRODUCT,
  SET_STANDARD_ORDER_ID,
  SET_STANDARD_COURIER,
  SET_STANDARD_PICK_UP,
  SET_STANDARD_DROP_OFF,
  SET_STANDARD_ITEM_TYPE,
  SET_STANDARD_INITIAL_STATE,
  SET_STANDARD_POINT_LOCATION,
  SET_STANDARD_PICKUP_DATETIME,
  SET_STANDARD_ITEM_DESCRIPTION,
  SET_STANDARD_ITEM_PRICE,
  SET_STANDARD_ITEM_APPROX_WEIGHT,
  SET_STANDARD_PICKUP_TIME,
  SET_STANDARD_PAYMENT_REQUEST_ID,
  SET_LOCATION,
  SET_STANDARD_CASHBACK_AMOUNT,
} from '../enums/types';

const initialState = STANDARD_INITIAL_STATE;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    const actionHandlers = {
      [SET_STANDARD_ORDER_ID]: 'orderId',
      [SET_STANDARD_POINT_LOCATION]: 'pointLocation',
      [SET_LOCATION]: 'location',
      [SET_STANDARD_COURIER]: 'courier',
      [SET_STANDARD_PICK_UP]: 'pickUp',
      [SET_STANDARD_DROP_OFF]: 'dropOff',
      [SET_STANDARD_PICKUP_DATETIME]: 'pickupDateTime',
      [SET_PRODUCT]: 'product',
      [SET_STANDARD_ITEM_TYPE]: 'itemType',
      [SET_STANDARD_ITEM_DESCRIPTION]: 'itemDescription',
      [SET_STANDARD_ITEM_PRICE]: 'itemPrice',
      [SET_STANDARD_ITEM_APPROX_WEIGHT]: 'itemApproxWeight',
      [SET_STANDARD_PICKUP_TIME]: 'pickUpTime',
      [SET_STANDARD_PAYMENT_REQUEST_ID]: 'paymentRequestId',
      [SET_STANDARD_CASHBACK_AMOUNT]: 'cashBackAmount',
      [SET_STANDARD_INITIAL_STATE]: () => initialState,
    };

    const updateProperty = actionHandlers[action.type];

    if (updateProperty) {
      if (typeof updateProperty === 'function') {
        return initialState;
      } else {
        draft[updateProperty] = action.payload;
      }
    }
  });
