import React from 'react';
import IconHourGlassLoader from '../../assets/svg/home-booking/icon_hourglass.svg';

const PlacingOrder = () => {
  return (
    <div className="placing__order">
      <img src={IconHourGlassLoader} alt="hour glass" className="hour-glass-img" />
      <div className="description">
        <span>We are placing your order</span>
        <span>Please do not close the app</span>
      </div>
    </div>
  );
};

export default PlacingOrder;
