import React from 'react'
import CancelledInfoSVG from '../../../../shared/assets/svg/get-info-cancelled.svg'
import ClickCloseIcon from '../../../../shared/assets/svg/click-close-icon.svg'

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <img src={CancelledInfoSVG} alt="Cancelled Info" width="180px" height="180px" />

      <div className='unauthorized-message'>
        <h3>Sorry, we are unable to get your information.</h3>
      </div>

      <div className="tip">
        <p>Please tap the </p>
        <img src={ClickCloseIcon} alt="close icon" />
        <p> to close the app.</p>
      </div>
    </div>
  )
}

export default Unauthorized