import React, { useEffect, useState } from 'react';
import IconOrderPlaced from '../../assets/svg/home-booking/icon_order_placed.svg';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../constants/Module';
import { useDispatch, useSelector } from 'react-redux';
import { DELIVERY_TYPE_VIEW } from '../../utils/enums/AppConstants';
import { Spin } from 'antd';
import { setTargetOrder } from '../../redux/home/actions';
import { sortList } from '../../utils/helpers/purefunctions';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import useRedirect from '../../hooks/useRedirect';

const OrderPlaced = ({ isFoodPandaCourier }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderList = useSelector(state => state.homeReducer.orderList);
  const latestOrderData = sortList(orderList);

  /* This is backup code in case CountdownCircleTimer package got error in the future */
  // const redirect = useRedirect()
  // if (redirect) {
  //   return handleNavigateOrderDetails();
  // }

  const handleNavigateOrderDetails = () => {
    dispatch(setTargetOrder(latestOrderData[0]));
    navigate(MODULE_PATH.ORDERS.ORDER_DETAILS);
  };

  return (
    <div className="placed__order">
      <img src={IconOrderPlaced} alt="Order Placed" />
      <div className="description">
        <span className="order_placed">Order has been placed!</span>
        <span>Please do not close the app</span>
      </div>
      <CountdownCircleTimer
        isGrowing={false}
        strokeWidth={2}
        trailStrokeWidth={1}
        isSmoothColorTransition={true}
        size={25}
        isPlaying
        duration={5}
        colors={['#005ce5']}
        onComplete={() => {
          return handleNavigateOrderDetails();
        }}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default OrderPlaced;
