import React from 'react'

const Preloader = () => {
  return (
    <div>
      <div className='lds-ellipsis-bg'>
        <div className='lds-ellipsis-loader'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Preloader
