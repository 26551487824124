import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { setKey, setDefaults, geocode, RequestType } from 'react-geocode';
import moment from 'moment-timezone';
import { Tour, message } from 'antd';
import axios from 'axios';

import kgImage from '../assets/images/OndemandDelivery/kg.svg';
import approxWeight from '../assets/images/OndemandDelivery/approxWeight.svg';
import startMapMarker from '../assets/images/OndemandDelivery/start_point_location.svg';
import arrowRight from '../assets/images/OndemandDelivery/arrow_right.svg';
import IconOnDemand from '../assets/svg/home-booking/icon_On-demand.svg';
import IconStandard from '../assets/svg/home-booking/icon_Standard.svg';
import PickupNow from '../assets/svg/home-booking/pickup_now.svg';
import PickupLater from '../assets/svg/home-booking/pickup_later.svg';
import IconItemDetails from '../assets/svg/home-booking/icon_item-details.svg';
import IconFindCourier from '../assets/svg/home-booking/icon_find-courier.svg';
import LogoNinjavan from '../assets/svg/home-booking/ninja_van_logo.svg';
import LogoXde from '../assets/svg/home-booking/xde_logo.svg';
import LogoQuadX from '../assets/svg/home-booking/quadx_logo.svg';
import LogoPandago from '../assets/svg/home-booking/pandaGo_logo.svg';
import LogoLalamove from '../assets/svg/home-booking/lalamove_logo.svg';
import Tap from '../assets/svg/home-booking/Tap.svg';
import LogoGrab from '../assets/svg/home-booking/grab_logo.svg';
import endMapMarker from '../assets/images/location/map-marker-alt.png';

import { MODULE_PATH } from '../constants/Module';
import Schedule from '../../app/body/OndemandDelivery/components/Schedule';
import FooterBtnHome from './FooterBtnHome';
import ItemDetailsList from '../components/ItemDetailsList';
import LoaderSpinner from '../components/LoaderSpinner';
import {
  setDropOff as setOnDemandDropOff,
  setLocation as setOnDemandLocation,
  setPointLocation as setOnDemandPointLocation,
  setPickUp as setOnDemandPickUp,
  setOrderId as setOnDemandOrderId,
  setVehicleType,
  setOnDemandItemType,
} from '../redux/onDemand/actions';
import {
  setDropOff as setStandardDropOff,
  setPickUp as setStandardPickUp,
  setLocation as setStandardLocation,
  setPointLocation as setStandardPointLocation,
  setOrderId as setStandardOrderId,
  setProduct,
  setStandardItemType,
} from '../redux/standard/actions';
import { setAppTourIdx } from '../redux/app/actions';
import { setFormTab, setPrevRoute, setTargetOrder } from '../redux/home/actions';
import { setCurrentUser } from '../redux/users/actions';
import { PRODUCT_SIZES, COURIER_PRODUCT_SIZES } from '../utils/enums/StandardDelivery';
import { CONVENIENCE_FEE } from '../utils/enums/DeliveryConstants';
import { BOOK_ANY, MAPS_API_KEY, MAPS_GEOCODE } from '../utils/enums/AppConstants';
import { ICON, ON_DEMAND_ICON, STANDARD_ICON } from '../utils/enums/AppIcons';
import { COURIER_IMAGES } from '../utils/enums/CourierImages';
import { FOOD_PANDA, PANDAGO, COURIER_VEHICLE_TYPES } from '../utils/enums/Providers';
import Login from '../utils/dao/Login';
import { DAY_MMM_D_HH_MM } from '../utils/enums/dateFormats';
import {
  capitalizeName,
  formatBills,
  isEmpty,
  formatUnderscoredText,
  generateOrderId,
} from '../utils/helpers/purefunctions';

const BookingForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [deliveryType, setDeliveryType] = useState('');
  const [isFillUp, setIsFillUp] = useState(false);
  const [showScheduleDrawer, setScheduleDrawer] = useState(false);
  const [showItemDetailsDrawer, setItemDetailsDrawer] = useState(false);

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const product = useSelector(state => state.standardReducer.product);
  const { view, formTab, prevRoute, targetOrder } = useSelector(state => state.homeReducer);
  const appAccess = useSelector(state => state.appAccessReducer);
  const user = useSelector(state => state.usersReducer.currentUser);

  const productWeightAndDimension = `${product?.description?.dimensionText} • ${
    product?.description?.weightText || ''
  }`;

  const isOndemand = 'onDemand-view' === formTab;
  const courierMode = user.courierMode;
  const isPickUpNow = onDemandProps.pickUpTime === 'PickUpNow' || onDemandProps.pickUpTime === '';

  const deliveryProps = isOndemand ? onDemandProps : standardProps;
  const setPointLocationPath = isOndemand ? setOnDemandPointLocation : setStandardPointLocation;
  const setLocationPath = isOndemand ? setOnDemandLocation : setStandardLocation;
  const {
    pickUp,
    dropOff,
    courier: selectedCourier,
    itemType,
    itemApproxWeight,
    vehicleType: selectedVehicle,
  } = deliveryProps;
  const courier = selectedCourier ? selectedCourier.courier : null;
  const courierRate = selectedCourier ? selectedCourier.rate : null;
  const prevCourierMode = !(courierMode === 'regular');

  const initialRefId = [
    '',
    'refDeliveryType',
    'refPickUpDropOff',
    'refPickNowLater',
    'refVehicleType',
    'refItemDetails',
    'refCourierList',
  ];

  const [tourIdx, setTourIdx] = useState(0);
  const [initialIds, setInitialIds] = useState({
    refDeliveryType: false,
    refPickUpDropOff: false,
    refPickNowLater: false,
    refVehicleType: false,
    refItemDetails: false,
    refCourierList: false,
  });
  const refs = {
    refDeliveryType: useRef(null),
    refPickUpDropOff: useRef(null),
    refPickNowLater: useRef(null),
    refVehicleType: useRef(null),
    refProductSize: useRef(null),
    refItemDetails: useRef(null),
    refCourierList: useRef(null),
  };

  const [currentAddress, setCurrentAddress] = useState('');

  setDefaults({
    key: MAPS_API_KEY,
  });

  setKey(MAPS_API_KEY);

  useEffect(() => {
    if (isEmpty(onDemandProps?.orderId) || isEmpty(standardProps.orderId)) {
      let num = 1;
      const set_OrderId = generateOrderId(num);
      dispatch(setStandardOrderId(set_OrderId));
      dispatch(setOnDemandOrderId(set_OrderId));
    }
    setDeliveryType(formTab);
  }, [dispatch, onDemandProps, standardProps]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;

          const setLocation = async () => {
            const response = await axios.get(
              `${MAPS_GEOCODE}?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`
            );

            if (response.data.results[0]) {
              const resData = response.data.results[0];
              setCurrentAddress(
                response.data?.plus_code?.compound_code || resData?.formatted_address
              );
            }
          };

          setLocation();
        },

        error => {
          console.error('Error getting user location:', error);
          message.info('Please turn on location');
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const delay = (fn, ms) => setTimeout(fn, ms);
  useEffect(() => {
    const isOndemandView = 'onDemand-view' === formTab;
    const addressValidation = isValid(
      !isEmpty(targetOrder)
        ? [targetOrder?.pickupAddress, targetOrder?.deliveryDetails?.deliveryAddress]
        : [pickUp?.senderDetails?.address, dropOff?.recipientDetails?.address]
    );
    if (isEmpty(prevRoute)) return;

    const navigateToProductList = () =>
      navigatePage(MODULE_PATH.STANDARD.PRODUCT_LIST, { state: { view } });
    const navigateToVehiclePage = () =>
      navigatePage(MODULE_PATH.SHARED.VEHICLE, { state: { view } });

    delay(async () => {
      dispatch(setPrevRoute(''));
      switch (prevRoute) {
        case 'ViewAll':
          if (addressValidation) {
            isOndemandView ? handleScheduler(true) : navigateToProductList();
            break;
          }
          break;

        case 'DropOff':
        case 'PickUp':
          if (addressValidation) {
            if (isOndemandView) {
              if (onDemandProps?.defaultTimeTag) {
                handleScheduler(true);
                break;
              }
            } else {
              if (standardProps.product?.default) {
                let productObj = { ...standardProps.product };
                delete productObj.default;
                dispatch(setProduct(productObj));
                navigateToProductList();
                break;
              }
            }
          }
          break;
        case 'PickUpNowLater':
          if (isOndemandView && selectedVehicle?.default) {
            let vehicleObj = { ...selectedVehicle };
            delete vehicleObj.default;
            dispatch(setVehicleType(vehicleObj));
            navigateToVehiclePage();
          }
          break;
        case 'vehicleType':
        case 'productSize':
          if (isEmpty(itemType)) {
            setItemDetailsDrawer(true);
            break;
          }

          if (isEmpty(courier)) {
            const vehicleChanged = prevRoute === 'vehicleType' && location?.state?.isVehicleChange;
            const productChanged =
              prevRoute === 'productSize' && location?.state?.isProductSizeChange;

            if (vehicleChanged || productChanged) {
              handleSelectCourier();
              break;
            }
          }
          break;
        case 'itemDetails':
          if (isOndemandView) {
            if (selectedVehicle?.default) {
              let vehicleObj = { ...selectedVehicle };
              delete vehicleObj.default;
              dispatch(setVehicleType(vehicleObj));
              navigateToVehiclePage();
              return;
            }
            if (isEmpty(courier)) {
              handleSelectCourier();
              return;
            }
          } else {
            if (standardProps.product?.default) {
              let productObj = { ...standardProps.product };
              delete productObj.default;
              dispatch(setProduct(productObj));
              navigateToProductList();
              return;
            }
            if (isEmpty(courier)) {
              handleSelectCourier();
              return;
            }
          }
          break;
        case 'couriersList':
          break;

        default:
          break;
      }
    }, 500);
  }, [prevRoute, onDemandProps, standardProps, formTab]);

  const isValid = arr => {
    const isTrue = [...arr].every(value => !isEmpty(value));
    return isTrue;
  };

  useEffect(() => {
    if (typeof appAccess.tourIdx === 'boolean') {
      setInitialIds({
        refDeliveryType: false,
        refPickUpDropOff: false,
        refPickNowLater: false,
        refVehicleType: false,
        refItemDetails: false,
        refCourierList: false,
      });
      setTourIdx(prevValue => prevValue + 1);
    }
  }, [appAccess.tourIdx, onDemandProps]);

  useEffect(() => {
    if (tourIdx < initialRefId.length) {
      if (!isEmpty(tourIdx)) {
        setTimeout(async () => {
          scrollToRef();
        }, 100);
      }
    } else {
      const currentUser = { ...user, tour: { ...(user?.tour || {}), touredForm: true } };
      dispatch(setAppTourIdx(undefined));
      dispatch(setCurrentUser(currentUser));
      const updateUserInfo = async () => {
        try {
          const loginDao = new Login();
          await loginDao.updateUser(user.id, currentUser);
        } catch (error) {
          console.error('Error:', error);
        }
      };

      updateUserInfo();
    }
  }, [tourIdx]);

  useEffect(() => {
    if (user?.tour?.touredForm) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      document.body.classList.remove('no-scroll');
    }
  }, [user]);

  const {
    lat,
    long,
    meta,
    gcash,
    status,
    province,
    pickupCity,
    barangay,
    customerName,
    contactNumber,
    pickupAddress,
    emailAddress,
  } = targetOrder;
  const deliveryDetails = meta ? JSON.parse(meta) : {};
  const [filterProduct] = PRODUCT_SIZES.filter(
    data => data.value.toLowerCase() === targetOrder?.product?.size
  );

  const pickupDetails = {
    barangay,
    province,
    city: pickupCity,
    address: pickupAddress,
    latitude: lat,
    longitude: long,
    senderDetails: {
      province,
      city: pickupCity,
      address: pickupAddress,
      contactNo: contactNumber,
      fullName: customerName,
      unitFloorHouse: gcash?.pickupUnitFloor,
      emailAddress: emailAddress || '',
    },
  };
  const dropOffDetails = {
    province: deliveryDetails.province,
    city: deliveryDetails.deliveryCity,
    address: deliveryDetails.deliveryAddress,
    barangay: deliveryDetails.barangay,
    recipientDetails: {
      province: deliveryDetails.province,
      city: deliveryDetails.deliveryCity,
      barangay: deliveryDetails.barangay,
      address: deliveryDetails.deliveryAddress,
      contactNo: deliveryDetails.contactNumber,
      fullName: deliveryDetails.customerName,
      unitFloorHouse: gcash?.dropOffUnitFloor,
    },
  };

  useEffect(() => {
    if (!isEmpty(targetOrder) && ['DONE', 'CANCELLED'].includes(status)) {
      dispatch(setFormTab(formTab));
      dispatch(setOnDemandItemType(gcash?.itemType));
      if (isOndemand) {
        dispatch(
          setOnDemandPickUp({
            latitude: lat,
            longitude: long,
            ...pickupDetails,
          })
        );
        dispatch(
          setOnDemandDropOff({
            latitude: deliveryDetails.lat,
            longitude: deliveryDetails.long,
            ...dropOffDetails,
          })
        );
      } else {
        dispatch(setStandardPickUp(pickupDetails));
        dispatch(setStandardDropOff(dropOffDetails));
      }
    }
    dispatch(setTargetOrder({}));
  }, []);

  useEffect(() => {
    const pickupAddress = pickUp?.senderDetails?.address || pickUp.address;
    const dropOffAddress = dropOff?.recipientDetails?.address || dropOff.address;
    setIsFillUp([pickupAddress, dropOffAddress, itemType, courier].every(value => !isEmpty(value)));
  }, [onDemandProps, standardProps]);

  const standardFillUp =
    !isEmpty(pickUp?.senderDetails?.address) &&
    !isEmpty(dropOff?.recipientDetails?.address) &&
    !isEmpty(itemType) &&
    !isEmpty(courier);

  const isFilledUpFields = isOndemand ? isFillUp : standardFillUp;

  const MODULE_ADDRESS = isOndemand
    ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
    : MODULE_PATH.STANDARD.SENDER_DETAILS;
  const MODULE_COURIER = isOndemand
    ? MODULE_PATH.SHARED.ON_DEMAND.COURIER
    : MODULE_PATH.SHARED.STANDARD.COURIER;
  const MODULE_PAYMENT_SUMMARY = isOndemand
    ? MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_SUMMARY
    : MODULE_PATH.PAYMENT.STANDARD.PAYMENT_SUMMARY;

  const onClickPickup = () => {
    if (typeof appAccess.tourIdx === 'boolean') return;
    dispatch(setPointLocationPath('PickUp'));

    if (!isEmpty(pickUp?.senderDetails)) {
      dispatch(setLocationPath(pickUp?.address));
      dispatch(setPrevRoute('PickUp'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true, currentAddress: currentAddress },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  const onClickDropOff = () => {
    dispatch(setPointLocationPath('DropOff'));

    if (!isEmpty(dropOff?.recipientDetails)) {
      dispatch(setLocationPath(dropOff?.address));
      dispatch(setPrevRoute('DropOff'));
      return navigate(MODULE_ADDRESS, {
        state: { showAutoComplete: true },
      });
    }

    navigate(MODULE_ADDRESS);
  };

  const transFormDeliveryType = () => {
    if (deliveryType === 'onDemand-view') {
      return deliveryType.replace(/onDemand-view/g, 'on-demand');
    }
    return deliveryType.split('-')[0];
  };

  const handleSelectCourier = () => {
    if (isEmpty(pickUp?.address) || isEmpty(dropOff?.address)) {
      return message.error('Pickup and Drop-off address are required.');
    }

    return navigate(MODULE_COURIER, {});
  };

  const DeliveryType = ({ type, className, deliveryIcon, refName }) => {
    return (
      <div
        className={`${className} ${deliveryType}`}
        onClick={() => handleSetDeliveryType(refName)}
      >
        <img src={deliveryIcon} alt={`${transFormDeliveryType()} logo`} />
        <div className="type">
          <span>{type}</span>
          <span>Delivery</span>
        </div>
      </div>
    );
  };

  const CourierLogos = () => {
    if (!deliveryType) return;
    const courierListLogo = {
      'onDemand-view': {
        logoList: [LogoPandago, LogoLalamove, LogoGrab],
        nameList: ['pandago', 'lalamove', 'grab'],
      },
      'standard-view': {
        logoList: [LogoNinjavan, LogoQuadX, LogoXde],
        nameList: ['ninjavan', 'quadx', 'xde'],
      },
    };

    const { logoList, nameList } = courierListLogo[deliveryType];

    return (
      <div className="courier-logos">
        {logoList.map((logo, index) => {
          return <img key={logoList[index]} src={logo} alt={nameList[index]} />;
        })}
      </div>
    );
  };

  const createStep = (title, description, target, coverClass) => {
    return {
      title: title,
      description: description,
      cover: (
        <div className={coverClass}>
          <img src={Tap} alt="Tap to Continue" className="tap-to-continue-bottom" />
          <span>Tap to Continue</span>
        </div>
      ),
      target: target,
    };
  };

  let refsIdx = initialRefId[tourIdx];
  const scrollToRef = () => {
    document.body.classList.remove('no-scroll');
    if (refs[refsIdx]?.current) {
      refs[refsIdx].current.scrollIntoView({ behavior: 'smooth' });

      document.body.classList.add('no-scroll');
      setTimeout(async () => {
        setInitialIds({ ...initialIds, [refsIdx]: true });
      }, 500);
    }
  };

  const stepsName = () => {
    switch (initialRefId[tourIdx]) {
      case 'refPickUpDropOff':
        return [
          createStep(
            'Sending something?',
            'Input your pickup & drop-off details here!',
            () => refs.refPickUpDropOff.current,
            'tour-delivery-type-description-bottom'
          ),
        ];
      case 'refPickNowLater':
        return [
          createStep(
            'Add Pickup Time',
            'Choose when to have your package picked up!',
            () => refs.refPickNowLater.current,
            'tour-delivery-type-description-bottom'
          ),
        ];
      case 'refVehicleType':
        return [
          createStep(
            'Select Vehicle Type!',
            'Choose how you want your package delivered!',
            () => refs.refVehicleType.current,
            'tour-delivery-type-description-top'
          ),
        ];
      case 'refItemDetails':
        return [
          createStep(
            'What are you shipping?',
            'Enter the package details here!',
            () => refs.refItemDetails.current,
            'tour-delivery-type-description-top'
          ),
        ];
      case 'refCourierList':
        return [
          createStep(
            'Select a courier!',
            'Compare and find the best rate among available couriers!',
            () => refs.refCourierList.current,
            'tour-delivery-type-description-top'
          ),
        ];
      default:
        return [
          createStep(
            'When to ship?',
            'Ship your deliveries ASAP or book in advance!',
            () => refs.refDeliveryType.current,
            'tour-delivery-type-description-bottom'
          ),
        ];
    }
  };

  const FieldSelection = ({ className, imageName, refIdx, ...rest }) => {
    const isFieldRequired = ['Item', 'Courier'].includes(imageName);
    const requiredCharIndicator = isFieldRequired ? '*' : '';
    // const requiredCharIndicator = '*';
    return (
      <div
        className={`field-container ${className}`}
        ref={refIdx === 'refProductSize' ? null : refs[refIdx]}
      >
        <div className={className} onClick={rest.onClick}>
          <img src={rest.imageSrc} alt={imageName} />
          <div className="title">
            {imageName === 'Courier' && !isEmpty(courier) ? (
              <span className="has-courier">{rest.description}</span>
            ) : (
              <>
                {imageName === 'Product size' && !isEmpty(standardProps?.product) ? (
                  <span>
                    {`Product size`}
                    <span className="title-emphasize">({rest.title})</span>
                    <span>{requiredCharIndicator}</span>
                  </span>
                ) : (
                  <span>
                    {rest.title} <span>{requiredCharIndicator}</span>
                  </span>
                )}
                <CardDescription imageName={imageName} rest={rest} />
              </>
            )}
          </div>
          {imageName === 'Courier' && <div className="price">{courierInfoRate()}</div>}
          <img src={arrowRight} alt="arrow-right" />
        </div>
      </div>
    );
  };

  const CardDescription = ({ imageName, rest }) => {
    return (
      <span>
        {imageName === 'Motorcycle' && !isEmpty(selectedVehicle) && (
          <img src={kgImage} alt={imageName} />
        )}
        {imageName === 'Item' && itemType && <img src={approxWeight} alt={imageName} />}
        {` ${rest.description} ${imageName === 'Item' && itemType ? 'Kg(s)' : ''}`}
      </span>
    );
  };

  const hasAddress = !isEmpty(pickUp?.address) && !isEmpty(dropOff?.address);
  const courierInfoLabel = () => {
    if (!isEmpty(courier) && hasAddress) {
      return capitalizeName(courier === FOOD_PANDA ? PANDAGO : courier.toLowerCase());
    }
    if (prevCourierMode && hasAddress) {
      return BOOK_ANY;
    }
    return 'Courier descriptions';
  };

  const courierInfoRate = () => {
    if (!isEmpty(courier) && hasAddress) {
      return `P ${formatBills(
        parseFloat(courierRate) +
          (courier === BOOK_ANY ? CONVENIENCE_FEE.ADVANCE : CONVENIENCE_FEE.REGULAR)
      )}`;
    }
    if (prevCourierMode && hasAddress && isEmpty(courier)) {
      return <LoaderSpinner />;
    }
    return;
  };

  const navigatePage = (path, state) => {
    if (typeof appAccess.tourIdx === 'boolean') return;
    return navigate(path, state);
  };

  const handleScheduler = bool => {
    if (typeof appAccess.tourIdx === 'boolean') return;
    return setScheduleDrawer(bool);
  };

  const handleSetDeliveryType = type => {
    if (!user?.tour?.touredForm) return;
    setDeliveryType(type);
    dispatch(setFormTab(type));
  };

  const pickUpLaterDisplay = `${moment(onDemandProps.pickupDateTime).format(DAY_MMM_D_HH_MM)}`;
  const courierImage =
    courier === BOOK_ANY ? ICON.BOOK_ANY : COURIER_IMAGES[courier?.toUpperCase()];

  const handleVehicleIcon = value => {
    let vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return ON_DEMAND_ICON[vehicleIcon];
  };

  const ProductIcon = value => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return STANDARD_ICON[productIcon];
  };

  const Ellips = val => {
    let words = val.trim().split(' ');
    let firstWord = words[0];
    if (firstWord.length > 6 || words.length === 1) {
      return firstWord;
    } else {
      return words[1].length > 6 ? words[0] : words[0] + ' ' + words[1];
    }
  };

  const hasFilled = [
    pickUp?.senderDetails?.address,
    dropOff?.recipientDetails?.address,
    itemType,
    courier,
  ].some(value => !isEmpty(value));

  const vehicleTypeDescription = `${
    (isOndemand && selectedVehicle?.description?.dimensionText) || ''
  } • ${(isOndemand && selectedVehicle?.description?.weightText) || ''}`;
  return (
    <>
      {typeof appAccess.tourIdx === 'boolean' && (
        <Tour
          maskClosable={!initialIds[refsIdx]}
          open={initialIds[refsIdx]}
          steps={stepsName()}
          placement={tourIdx < 4 ? 'bottom' : 'top'}
          className="tour-delivery-type"
        />
      )}

      <div className="booking-form-container">
        <div className="card-1 v4">
          <div className="delivery-type" ref={refs.refDeliveryType}>
            <DeliveryType
              deliveryIcon={IconOnDemand}
              className="on-demand"
              type="On-demand"
              refName="onDemand-view"
            />
            <DeliveryType
              deliveryIcon={IconStandard}
              className="standard"
              type="Standard"
              refName="standard-view"
            />
          </div>
          <div className="courier-logo-list">
            <div className="courier-logo-list-container">
              <span>{`Book ${transFormDeliveryType()} couriers`}</span>
              <CourierLogos />
            </div>
          </div>
          <div className="form__input" ref={refs.refPickUpDropOff}>
            <div className="map-marker">
              <img src={startMapMarker} alt="end-map-marker" />
              <div className="vertical-line"></div>
              <img src={endMapMarker} alt="start-map-marker" />
            </div>
            <div className="input-field">
              {isEmpty(pickUp?.senderDetails?.fullName) ? (
                <input
                  className="pickup-input"
                  placeholder="Pickup location"
                  onClick={() => {
                    if (!user?.tour?.touredForm) return;
                    onClickPickup();
                  }}
                  defaultValue=""
                  value={pickUp?.senderDetails?.fullName || ''}
                  readOnly
                />
              ) : (
                <div className="user-info" onClick={() => onClickPickup()}>
                  <span className="no-wrap">{Ellips(pickUp?.senderDetails?.fullName)}</span>
                  <span className="address-info">{pickUp.address}</span>
                </div>
              )}
              {isEmpty(dropOff?.recipientDetails?.fullName) ? (
                <input
                  className="dropoff-input"
                  placeholder="Drop-off location"
                  defaultValue=""
                  value={dropOff?.recipientDetails?.fullName || ''}
                  onClick={() => {
                    if (!user?.tour?.touredForm) return;
                    onClickDropOff();
                  }}
                  readOnly
                />
              ) : (
                <div
                  className="user-info"
                  onClick={() => {
                    onClickDropOff();
                  }}
                >
                  <span className="no-wrap">{Ellips(dropOff?.recipientDetails?.fullName)}</span>
                  <span className="address-info">{dropOff.address}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-2 v4">
          <FieldSelection
            className={`pickup-now ${deliveryType}`}
            imageName="Pickup Now"
            imageSrc={isPickUpNow ? PickupNow : PickupLater}
            title={isPickUpNow ? 'Pickup now' : 'Pickup later '}
            description={isPickUpNow ? 'Pickup your item as soon as possible' : pickUpLaterDisplay}
            onClick={() => {
              if (!user?.tour?.touredForm) return;
              handleScheduler(true);
            }}
            refIdx="refPickNowLater"
          />
          {isOndemand && (
            <FieldSelection
              className={`motorcycle ${deliveryType}`}
              imageName="Motorcycle"
              imageSrc={handleVehicleIcon(selectedVehicle.vehicleType)}
              onClick={() => {
                if (!user?.tour?.touredForm) return;
                navigatePage(MODULE_PATH.SHARED.VEHICLE, { state: { view: view } });
              }}
              title={
                isOndemand
                  ? selectedVehicle?.vehicleType === 'MPV'
                    ? selectedVehicle?.vehicleType
                    : formatUnderscoredText(selectedVehicle.vehicleType)
                  : ''
              }
              description={`${
                (isOndemand && selectedVehicle?.description?.dimensionText) || ''
              } • ${(isOndemand && selectedVehicle?.description?.weightText) || ''}`}
              refIdx="refVehicleType"
            />
          )}
          <FieldSelection
            className={`product-size ${deliveryType}`}
            imageName="Product size"
            imageSrc={ProductIcon(standardProps?.product?.value || '')}
            onClick={() => {
              navigatePage(MODULE_PATH.STANDARD.PRODUCT_LIST, { state: { view: view } });
            }}
            title={standardProps?.product?.value}
            description={productWeightAndDimension}
          />
          <FieldSelection
            className="item-details"
            imageName="Item"
            imageSrc={IconItemDetails}
            title={capitalizeName(itemType) || 'Add item details'}
            description={itemType ? parseFloat(itemApproxWeight) : 'Item description'}
            refIdx="refItemDetails"
            onClick={() => {
              if (!user?.tour?.touredForm) return;
              if (typeof appAccess.tourIdx === 'boolean') return;
              setItemDetailsDrawer(true);
            }}
          />
          <FieldSelection
            className="find-courier"
            imageName="Courier"
            imageSrc={isEmpty(courier) ? IconFindCourier : courierImage}
            title="Find a courier"
            description={courierInfoLabel()}
            refId="ref-courier-list"
            onClick={() => {
              if (!user?.tour?.touredForm) return;
              handleSelectCourier();
            }}
            refIdx="refCourierList"
          />
        </div>
      </div>
      <Schedule
        show={showScheduleDrawer}
        setScheduleDrawer={() => {
          handleScheduler(false);
        }}
        onSetVehicle={() => {
          if (!user?.tour?.touredForm) return;
          // SPRINT3 uncomment
          // setTimeout(async () => {
          //   navigatePage(MODULE_PATH.SHARED.VEHICLE, { state: { view: view } });
          // }, 500);
        }}
      />
      <ItemDetailsList
        show={showItemDetailsDrawer}
        onCloseItemDetailsDrawer={() => setItemDetailsDrawer(false)}
      />
      <FooterBtnHome
        className={`home-booking ${!isFilledUpFields ? 'disabled' : ''}`}
        childClass="radius"
        label="Review Order"
        onClick={() => {
          if (!isFilledUpFields) return;
          navigate(MODULE_PAYMENT_SUMMARY);
        }}
        hasFilled={hasFilled}
      />
    </>
  );
};

export default BookingForm;
