import React, { createContext, useState } from 'react';

const ItemTypeContext = createContext();

export const InputItemType = ({ children }) => {
  const [type, setType] = useState('');

  return <ItemTypeContext.Provider value={{ type, setType }}>{children}</ItemTypeContext.Provider>;
};

export default ItemTypeContext;
