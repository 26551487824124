export const STANDARD_INITIAL_STATE = {
  pointLocation: '',
  courier: {},
  pickUpTime: 'PickUpNow',
  pickUp: {
    latitude: 0,
    longitude: 0,
    senderDetails: {},
    notesToRider: '',
    pickupAddress: '',
    barangay: '',
    pickupCity: '',
    province: '',
  },
  dropOff: {
    latitude: 0,
    longitude: 0,
    recipientDetails: {},
    notesToRider: '',
    deliveryAddress: '',
    barangay: '',
    deliveryCity: '',
    province: '',
  },
  pickupDateTime: null,
  itemType: '',
  itemDescription: '',
  itemPrice: '',
  itemApproxWeight: 1,
  // removed: product default value is {}
  product: {
    sizeName: 'Small (S)',
    value: 'Small',
    description: {
      weightText: 'Up to 1Kg',
      dimensionText: '11 x 6 x 1 in',
    },
    default: true,
  },
  // product: {},
  defaultAddress: false,
  paymentRequestId: '',
  orderId: '',
  paymentReqs: {},
  paymentRes: {},
  cashBackAmount: 0,
  itemApproxWeight: 1,
};
