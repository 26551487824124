import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { ORDER_STATUS_IMAGE } from '../utils/enums/OrderStatus';
import { MODULE_PATH } from '../constants/Module';

const FailedOrder = () => {
  const navigate = useNavigate();
  return (
    <div className="error__order__container">
      <img src={ORDER_STATUS_IMAGE['ORDER_CANCELLED']} alt="Order Status Icon" />
      <div className="header">Oops!</div>
      <div className="sub-header">An error occured while placing your order.</div>
      <div className="text">We are sorry to tell you that your order has been cancelled.</div>
      <div className="refund-process">
        <div className="content">
          <FontAwesomeIcon icon={solid('info-circle')} className="info-circle" />
          <span>
            We are currenty processing your refund. Your refund will be credited to your GCash
            account within 3-5 business days.
          </span>
        </div>
      </div>
      <Button className="need-help-btn" onClick={() => navigate(MODULE_PATH.EXTRAS.HELP)}>
        <FontAwesomeIcon icon={solid('envelope-open-text')} />
        Need Help?
      </Button>
    </div>
  );
};

export default FailedOrder;
