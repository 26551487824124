import {
  SET_FORM_VIEW,
  SET_LOADER,
  SET_FETCH,
  SET_FETCH_ALL,
  SET_ORDER_LIST,
  SET_TARGET_ORDER,
  SET_ORDER_LIST_MONGO,
  SET_FOR_REFUND_ORDER_LIST,
  SET_CANCELLED_ORDER_LIST,
  SET_COMPLETED_ORDER_LIST,
  SET_TOOL_TIP,
  SET_FORM_TAB,
  SET_PREV_ROUTE,
  SET_CASHBACK_TRANSACTIONS_LIST,
} from '../enums/types';
import OrderDAO from '../../../shared/utils/dao/Orders';
import { isEmpty } from '../../utils/helpers/purefunctions';
import PaymentDAO from '../../utils/dao/PaymentDAO';
import moment from 'moment-timezone';

export const setFormView = view => ({
  type: SET_FORM_VIEW,
  payload: view,
});

export const setLoader = loader => ({
  type: SET_LOADER,
  payload: loader,
});

export const setFetch = fetch => ({
  type: SET_FETCH,
  payload: fetch,
});

export const setFetchALL = fetch => ({
  type: SET_FETCH_ALL,
  payload: fetch,
});

export const setTargetOrder = order => ({
  type: SET_TARGET_ORDER,
  payload: order,
});

export const setOrderList = orders => ({
  type: SET_ORDER_LIST,
  payload: orders,
});

export const setForRefundOrderList = forRefundOrders => ({
  type: SET_FOR_REFUND_ORDER_LIST,
  payload: forRefundOrders,
});

export const setCancelleddOrderList = cancelledOrders => ({
  type: SET_CANCELLED_ORDER_LIST,
  payload: cancelledOrders,
});

export const setCompletedOrderList = completedOrders => ({
  type: SET_COMPLETED_ORDER_LIST,
  payload: completedOrders,
});

export const setMongoOrderList = orders => ({
  type: SET_ORDER_LIST_MONGO,
  payload: orders,
});

export const setShowTooltip = tooltip => ({
  type: SET_TOOL_TIP,
  payload: tooltip,
});

export const setFormTab = formTab => ({
  type: SET_FORM_TAB,
  payload: formTab,
});

export const setPrevRoute = prevRoute => ({
  type: SET_PREV_ROUTE,
  payload: prevRoute,
});

export const setCashbackTransaction = payload => ({
  type: SET_CASHBACK_TRANSACTIONS_LIST,
  payload,
});

const fetchOrders = (orderType, actionSetter) => createdBy => {
  return async (dispatch, getState) => {
    const state = getState();
    const orderId = state.homeReducer.targetOrder;
    const orderDao = new OrderDAO();
    await orderDao[orderType](createdBy, orderId, (orders, selectedOrder) => {
      dispatch(actionSetter(orders));
      !isEmpty(selectedOrder) && dispatch(setTargetOrder(selectedOrder));

      orderType === 'getPendingOrders' && dispatch(setFetch(true));
    });
  };
};

export const fetchPendingOrders = fetchOrders('getPendingOrders', setOrderList);
export const fetchForRefundOrders = fetchOrders('getForRefundOrders', setForRefundOrderList);
export const fetchCancelledOrders = fetchOrders('getCancelledOrders', setCancelleddOrderList);

export const fetchDoneOrders = (createdBy, offset) => {
  return async (dispatch, getState) => {
    const state = getState();
    const orderId = state.homeReducer.targetOrder;
    const orderDao = new OrderDAO();
    await orderDao.getDoneOrders(createdBy, orderId, offset, (completeOrders, selectedOrder) => {
      dispatch(setCompletedOrderList(completeOrders));
      !isEmpty(selectedOrder) && dispatch(setTargetOrder(selectedOrder));
    });
  };
};

export const fetchOrdersFromMongo = createdBy => {
  return async (dispatch, getState) => {
    if (!createdBy) {
      return dispatch(setMongoOrderList([]));
    }

    const paymentDao = new PaymentDAO();
    const orderListFromMongo = await paymentDao.getTransaction(createdBy);

    let orderDetails = [];
    orderListFromMongo?.result?.forEach(item => {
      orderDetails.push({
        mdbId: item._id,
        createdAt: moment(item.createdAt.split('.')[0]).valueOf(),
        ...JSON.parse(item.orderDetails),
      });
    });

    dispatch(setMongoOrderList(orderDetails));
  };
};

export const fetchCashbackTransactions = userId => {
  return async (dispatch, getState) => {
    if (!userId) {
      return dispatch(setCashbackTransaction([]));
    }
    const paymentDao = new PaymentDAO();
    const cashbackTransactions = await paymentDao.getCashBackTransactions(userId);
    dispatch(
      setCashbackTransaction(cashbackTransactions?.success ? cashbackTransactions.result : [])
    );
  };
};
