export const TERMS_AND_CONDITIONS = {
  description:
    'These terms and conditions ("Terms") govern your use of the delivery booking aggregator app ("App") provided by CTO Global Services, Inc, a.k.a. Last Mile Inc. ("Company"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using the App',
  contents: [
    {
      title: 'User Eligibility',
      body: [
        '1.1. To use the App, you must be at least 18 years old and capable of entering into legally binding contracts. By using the App, you represent and warrant that you meet these requirements',
        '1.2. If you are using the App on behalf of an organization or entity, you represent and warrant that you have the authority to bind that organization or entity to these Terms',
      ],
    },
    {
      title: 'App Usage',
      body: [
        '2.1. The App provides a platform that allows users to book delivery services provided by third-party delivery service providers ("Delivery Providers").',
        '2.2. The Company acts solely as an intermediary between users and Delivery Providers. The Company does not provide delivery services directly and is not responsible for the actions or omissions of the Delivery Providers',
        '2.3. Users are solely responsible for their interactions and transactions with Delivery Providers. The Company shall not be liable for any disputes, damages, or losses arising from such interactions or transactions.',
        '2.4. The Company reserves the right to modify, suspend, or terminate the App or any part of it at any time without prior notice.',
      ],
    },
    {
      title: 'User Responsibilities',
      body: [
        '3.1. Users agree to provide accurate and complete information when using the App, including but not limited to delivery addresses, contact details, and payment information.',
        '3.2. Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. Users must notify the Company immediately of any unauthorized use of their account or any other breach of security.',
        '3.3. Users agree not to use the App for any unlawful, fraudulent, or unauthorized purposes. Users shall comply with all applicable laws, regulations, and third-party rights while using the App.',
        `3.4. Users shall not interfere with the proper functioning of the App or engage in any activity that could damage, disable, or impair the App's infrastructure, security, or features`,
      ],
    },
    {
      title: 'Payment and Fees',
      body: [
        '4.1. Users agree to pay the fees and charges associated with the delivery services as displayed in the App. All payments shall be made through the payment methods provided in the App.',
        '4.2. The Company reserves the right to modify the fees and charges at any time, and such changes shall be effective immediately upon posting in the App.',
        '4.3. The Company may retain a commission or service fee for facilitating the delivery service bookings through the App. The commission or service fee will be clearly indicated in the App.',
        `4.4. Users understand and agree that the Company does not provide refunds for delivery services unless otherwise specified by the Delivery Provider`,
      ],
    },
    {
      title: 'Claims and Disputes with Delivery Providers',
      body: [
        '9.1. Users acknowledge and agree that the delivery services are provided by third-party Delivery Providers, and the terms and conditions governing the delivery services are specified by the respective Delivery Providers.',
        `9.2. Users have the option to review and accept the Delivery Provider's terms and conditions within the App before booking a delivery service. By accepting the Delivery Provider's terms and conditions, users agree to be bound by them.`,
        '9.3. In the event of any claims, disputes, damages, or losses arising from the delivery services provided by a chosen Delivery Provider, users may need to directly contact and resolve the issue with the Delivery Provider according to their terms and conditions.',
        `9.4. The Company shall not be responsible or liable for any claims, disputes, damages, or losses arising from the delivery services provided by a chosen Delivery Provider. The Company's liability is limited to the extent of facilitating the booking of delivery services through the App.`,
      ],
    },
    {
      title: 'Intellectual Property',
      body: [
        '6.1. The App and all its contents, including but not limited to text, graphics, logos, images, and software, are the property of the Company or its licensors and are protected by intellectual property laws.',
        `6.2. Users are granted a limited, non-exclusive, non-transferable, and revocable license to use the App solely for the purpose of booking delivery services. Users shall not reproduce, modify, distribute, or create derivative works of the App or its contents without prior written consent from the Company.`,
      ],
    },
    {
      title: 'Privacy',
      body: [
        `7.1. The Company's collection, use, and disclosure of personal information are governed by the Company's Privacy Policy, which is incorporated by reference into these Terms`,
      ],
    },
    {
      title: 'Limitation of Liability',
      body: [
        `8.1. The Company shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the App or the delivery services, including but not limited to lost profits, loss of data, or loss of business opportunities.`,
        `8.2. To the maximum extent permitted by applicable law, the total liability of the Company, regardless of the cause of action, shall be limited to the fees paid by the user to the Company for the use of the App in the six months preceding the event giving rise to the liability.`,
      ],
    },
    {
      title: 'Governing Law and Dispute Resolution',
      body: [
        `9.1. These Terms shall be governed by and construed in accordance with the laws of Republic of the Philippines. Any disputes arising out of or relating to these Terms or the use of the App shall be resolved exclusively by the courts of Makati City, Philippines.`,
      ],
    },
  ],
};
